import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Image, Grid, Checkbox, Button, Form, Input, Modal, Message } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useToasts } from 'react-toast-notifications';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';

import { Calendar } from 'primereact/calendar';
import { changeFormatDateTimeDB, packageColor, packageColorDB, removeEmojis, removeSpecialChars } from '../../../../myfunctions';


function CloneTournament({ setcloneTournamentOpenModal, cloneTournamentOpenModal }) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [loading, setloading] = useState(false);
  const [form, setForm] = useState({
    source_tournament_id: 0,
    tournament_name: '',
    start_date: '',
    stop_date: '',
    register_stop_date: '',
    copy_categories: true,
    copy_members: false,
  })

  function plusSevenDay(date_value){
    let date = new Date(date_value);
    date.setDate(date.getDate() + 7);
    return date
  }

  useEffect(() => {


    if (cloneTournamentOpenModal) {

      setForm({
        ...form,
        source_tournament_id: cloneTournamentOpenModal.tournament_id,
        tournament_name: cloneTournamentOpenModal.name,
        start_date: plusSevenDay(cloneTournamentOpenModal.start_date),
        stop_date: plusSevenDay(cloneTournamentOpenModal.stop_date),
        register_stop_date: plusSevenDay(cloneTournamentOpenModal.start_date),
      })
    }

    return () => {


    }; // use effect cleanup to set flag false, if unmounted
  }, [cloneTournamentOpenModal])// eslint-disable-line react-hooks/exhaustive-deps


  const submitCloneTournament = async e => {
    e.preventDefault();


    setloading(true)
    ConnectWS("/generator/copyTournament", authContext.token, {
      source_tournament_id: form.source_tournament_id,
      tournament_name: form.tournament_name,
      start_date: changeFormatDateTimeDB(form.start_date),
      stop_date: changeFormatDateTimeDB(form.stop_date),
      register_stop_date: changeFormatDateTimeDB(form.register_stop_date),
      copy_categories: form.copy_categories,
      copy_members: form.copy_members,
    }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        setcloneTournamentOpenModal(null)
        authContext.changeRefreshView(authContext.refreshView + 1)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }
      setloading(false)



    });

  }


  return (
    <Modal
      open={cloneTournamentOpenModal ? true : false}
      size={'large'}


    >
      <Modal.Header>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as='h4' textAlign='center'>
                <Icon name='clone' />
                <Header.Content>Kopiowanie turnieju</Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" only='large screen'>
              <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Modal.Header>
      <Modal.Content>

        <Modal.Description>
        {cloneTournamentOpenModal ? cloneTournamentOpenModal.tournament_free === 0 ? <Message info>Kopiowanie dostępne tylko dla turniejów w pakiecie SREBRNYM {packageColor(2)}lub ZŁOTYM {packageColor(3)}</Message> :
          <Form onSubmit={submitCloneTournament} size='mini' >
            <Form.Field
              control={Input}
              value={form.tournament_name}
              onChange={(event, data) => setForm({ ...form, tournament_name: (removeSpecialChars(removeEmojis(data.value))) })}
              label='Nazwa rozgrywek'
              placeholder='Nazwa rozgrywek'
              required={true}

            />
            <Form.Field >
              <label>Start rozgrywek</label>
              <Calendar value={form.start_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, start_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>
            <Form.Field >
              <label>Koniec rozgrywek</label>
              <Calendar value={form.stop_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, stop_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>

            <Form.Field >
              <label>Koniec zapisów</label>
              <Calendar value={form.register_stop_date} dateFormat="yy-mm-dd" locale="pl" onChange={(e) => e.value !== null ? setForm({ ...form, register_stop_date: e.value }) : ""} showTime hourFormat="24" />

            </Form.Field>
            <Form.Field >
              <Checkbox toggle label="Czy utworzyć kategorie?" onChange={(e, d) => !d.checked ? setForm({ ...form, copy_categories: d.checked, copy_members: false }) : setForm({ ...form, copy_categories: d.checked })} checked={form.copy_categories ? true : false} />
            </Form.Field>
            <Form.Field >
              <Checkbox disabled={!form.copy_categories} toggle label="Czy skopiować uczestników?" onChange={(e, d) => setForm({ ...form, copy_members: d.checked })} checked={form.copy_members ? true : false} />
            </Form.Field>

            {
              cloneTournamentOpenModal ? <Form.Field > {packageColorDB(cloneTournamentOpenModal.tournament_free)}</Form.Field> : ""
            }

             <Button loading={loading} disabled={loading} fluid>UTWÓRZ </Button> 
            

          </Form>
:""}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setcloneTournamentOpenModal(null)}>
          ZAMKNIJ
        </Button>

      </Modal.Actions>
    </Modal>

  )



}

export default CloneTournament;