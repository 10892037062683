import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Icon, Header, Divider, Segment, Image, Grid, Table, Checkbox, Label, List, Button, Message, Progress, Form, Input, Modal, Dropdown, Tab, Container, Card, Sticky } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { ConnectWS } from '../../../../ConnectWS';
import BracketGen from '../../Bracket/BracketGen';
import Lightbox from 'react-image-lightbox';
import useAuth from '../../../../hooks/useAuth';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import MyTournamentAddUsers from './MyTournamentAddUsers';
import { LiveIcon, packageColor, changePageName, changeFormatDateTimeBS, getlinkplayerprofile, showEloP, getlinktournament, numberToCharTG, getAddressLocationGoole, saveCsvTab, getLimitUsersPackage, checkDateStartBeforeNow, getlinktournamentTV, checkisglicko, getInfoTab } from '../../../../myfunctions'
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert'; // Import
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import StepCreateTournament from '../MyAccount/StepCreateTournament';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import noimage from '../../../../assets/noimage.png';

import cali6 from '../../../../assets/partners/6cali.png';
import BracketGroups from '../../Bracket/BracketGroups';
import BracketGroupsEWE from '../../Bracket/BracketGroupsEWE';
import GenQrCode from '../../SinglePage/Other/GenQrCode';
import MyTournamentEditUsers from './MyTournamentEditUsers';
import BracketSWISS from '../../Bracket/BracketSWISS';
import { CodeBlock } from "react-code-blocks";
import { useParams } from "react-router-dom";
import ShowVideoPlayer from '../../../../tools/ShowVideoPlayer';
import ShowImgAvatar from '../../../../tools/ShowImgAvatar';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import AdSense from 'react-adsense';
import { useReactToPrint } from 'react-to-print';
import TimerTournamentDemo from '../../../../tools/TimerTournamentDemo';
import AddEmailForMember from '../../../../tools/AddEmailForMember';
import { Markup } from 'interweave';
import MyImageLazy from '../../../../tools/MyImageLazy';
import EditRowFinalTable from '../../../../tools/EditRowFinalTable';
import ChangeLimitMembers from '../../../../tools/ChangeLimitMembers';
import BracketLeague from '../../Bracket/BracketLeague';
import GetTableScore from '../../Bracket/GetTableScore';
import FinalTableCalcPointsSystems from '../../SinglePage/Other/FinalTableCalcPointsSystems';
import ImportMembers from '../../../../tools/ImportMembers';
import GetEditorTG from '../../../../tools/GetEditorTG';
import MembersInTeams from '../../../../tools/MembersInTeams';
import i18next from 'i18next';
import BracketSWISSCup from '../../Bracket/BracketSWISSCup';
import AddMembersToTournament from '../../../../tools/AddMembersToTournament';



function MyTournament(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [tournamentJson, settournamentJson] = useState({});
  const [photoIndex, setphotoIndex] = useState(0)
  const [isOpen, setisOpen] = useState(false)
  const [openIframe, setopenIframe] = useState(false)
  const [editBracket, seteditBracket] = useState(true)// eslint-disable-line no-unused-vars
  const [tournamentId, settournamentId] = useState("")// eslint-disable-line no-unused-vars

  const [MembersLastCyclePlaces, setMembersLastCyclePlaces] = useState(null)
  const [openMembers, setopenMembers] = useState({ open: false, data: null })
  const [openModalMembersTeams, setopenModalMembersTeams] = useState({ open: false, data: null })
  const [openModalAddMembersToTournament, setopenModalAddMembersToTournament] = useState({ open: false, data: null })
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  //const [elRefsPrint, setElRefsPrint] = React.useState([]);
  const [rte, setrte] = useState("");

  const [loading, setloading] = useState(false)
  const [loadingdelt, setloadingdelt] = useState(false)
  const [loadingMT, setloadingMT] = useState(false)

  let contextRef = createRef()

  const [tmpTotalPointInfoArr, settmpTotalPointInfoArr] = useState(null)

  const isDemo = useCheckUserIsDemo()

  const [fullscreen, setfullscreen] = useState(false)// eslint-disable-line no-unused-vars

  const [onlyCategory, setonlyCategory] = useState(false)

  const [searchMember, setsearchMember] = useState({ search: null, resultSearch: null })
  const [loadingsearchMember, setloadingsearchMember] = useState(false)
  const [limitUsers, setlimitUsers] = useState(0) // eslint-disable-line no-unused-vars


  const [openChangeUser, setopenChangeUser] = useState(false);
  const [openChangeUserData, setopenChangeUserData] = useState({ open: false, data: null });
  const [ChangeUsertab, setChangeUsertab] = useState(null);
  const { tournamentIdUP } = useParams()
  const [panes, setpanes] = useState([]);
  const [formChangeUser, setformChangeUser] = useState({
    memberInfoCurrent: null,
    subtournament_id: null,
    memberInfoOld: null,

  });


  const [dataMailMembers, setdataMailMembers] = useState({ open: false, data: null })

  const [final_table_calc_points_system_id, setfinal_table_calc_points_system_id] = useState(0);

  const organizer_confirmed_def = [
    {
      key: 'TAK',
      text: 'TAK',
      value: 1,
      content: "TAK",
    },
    {
      key: 'NIE',
      text: 'NIE',
      value: 0,
      content: "NIE",
    },
  ]


  useEffect(() => {

    if (props.onlyCategory) {
      console.log("onlyCategory", props.onlyCategory)
      setonlyCategory(props.onlyCategory)
    }

    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: MyTournament", props)
    }


    if (tournamentIdUP) {
      settournamentId(tournamentIdUP)
      getTournamentData(tournamentIdUP)
    } else if (props.ViewProps.tournamentId) {
      settournamentId(props.ViewProps.tournamentId)
      getTournamentData(props.ViewProps.tournamentId)
    }




    return () => {
      authContext.changeLiveIcon(0)

    }; // use effect cleanup to set flag false, if unmounted
  }, [authContext.refreshView, authContext.onlyOneColumn, i18next.language])// eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    createPane(tournamentJson, tournamentJson.tournament_free)
    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [searchMember.search, searchMember.resultSearch, final_table_calc_points_system_id, tmpTotalPointInfoArr, MembersLastCyclePlaces])// eslint-disable-line react-hooks/exhaustive-deps





  function returnButtonFTSM(line, possible_members_upgrade, tournament_id) {
    let ismail = 0;
    if (line.members && line.members.length > 0) {
      line.members.forEach((row) => {
        if (row.email !== null) {
          ismail++
        }
      })
    }
    if (ismail === 0) {
      return <>
        <Button className='hiddenPrint' fluid size='tiny' color='brown' disabled={true} >ZAKOŃCZ ROZGRYWKI, WYGENERUJ TABELE KOŃCOWĄ ORAZ WYŚLIJ MAILA DO UCZESTNIKÓW Z PODSUMOWANIEM ROZGRYWEK</Button>
        <Message className='hiddenPrint' size='mini'>
          Niestety, wskazane rozgrywki nie posiadają uczestników z wprowadzonymi adresami email. Uzupełnienie danych pozwoli powiadomić uczestników o wynikach rozgrywek oraz stworzy unikalne profile w serwisie, dzięki czemu każdy będzie mógł śledzić swoje statystyki oraz postępy. Jeśli nie chcesz uzupełniać danych skorzystaj z opcji ZAKOŃCZ ROZGRYWKI, WYGENERUJ TABELĘ KOŃCOWĄ.
        </Message>
      </>
    } else {
      return <>
        <Button className='hiddenPrint' fluid size='tiny' color='brown' onClick={() => finishTournament(line.final_table.length, line.subtournament_id, true, possible_members_upgrade, tournament_id)} >ZAKOŃCZ ROZGRYWKI, WYGENERUJ TABELE KOŃCOWĄ ORAZ WYŚLIJ MAILA DO UCZESTNIKÓW Z PODSUMOWANIEM ROZGRYWEK</Button>
        <Message className='hiddenPrint' size='mini'>
          Zostaną wysłane emaile do <b>{ismail}</b> uczestników z podsumowaniem rozgrywek.
        </Message>
      </>
    }

  }

  function addMemberMail(line2) {
    setdataMailMembers({ open: true, data: { ...line2, tournament_member_ids: [line2.id] } })
  }


  function getMembersLastCyclePlaces(tournament_id) {


    ConnectWS("/generator/getMembersLastCyclePlaces", authContext.token, { tournament_id: tournament_id }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        setMembersLastCyclePlaces(response.data)
      } else {

        if (logout === 101) {
          // addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          //  addToast(response, { appearance: 'warning' });
        } else {
          //  addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


    });
  }


  function changeMemberType(subtournament_id, member_type) {
    let member_typemtp = member_type === "USER" ? 'TEAM' : 'USER'
    setloadingMT(true)
    ConnectWS("/generator/editSubtournamentMemberType", authContext.token, { subtournament_id: subtournament_id, member_type: member_typemtp }, function (response, logout) {
      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        setloadingMT(false)
      } else if (response && response.status && Number(response.status) === 200) {
        setloadingMT(false)
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        setloadingMT(false)
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


    });
  }

  function addNewMemberToGroupAcc(tournament_member_id, subtournament_id) {
    ConnectWS("/generator/addNewMemberToGroup", authContext.token, { subtournament_id: subtournament_id, tournament_member_id: tournament_member_id }, function (response, logout) {
      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        setloadingMT(false)
      } else if (response && response.status && Number(response.status) === 200) {
        setloadingMT(false)
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        setloadingMT(false)
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


    });
    //generator/addNewMemberToGroup -> subtournament_id, tournament_member_id
  }


  function setFlagMemberOrganizerConfirmed(tournament_member_id, tournament_id, value) {

    ConnectWS("/generator/setFlagMemberOrganizerConfirmed", authContext.token, { tournament_id: tournament_id, tournament_member_id: tournament_member_id, flag_organizer_confirmed: value }, function (response, logout) {
      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


    });
  }

  function addMemberToLeagueAcc(subtournament_member_id, subtournament_id) {
    ConnectWS("/generator/addMemberToLeague", authContext.token, { subtournament_id: subtournament_id, subtournament_member_id: subtournament_member_id }, function (response, logout) {
      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        setloadingMT(false)
      } else if (response && response.status && Number(response.status) === 200) {
        setloadingMT(false)
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {
        setloadingMT(false)
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }


    });
    //generator/addNewMemberToGroup -> subtournament_id, tournament_member_id
  }

  function addNewMemberToGroup(line2, line) {
    if (line.type === "GROUP") {
      return <Icon color='blue' className='cursorPointer' title="Dodaj uczestnika do turnieju" name="add" onClick={() => addNewMemberToGroupAcc(line2.id, line.subtournament_id)} />
    } else if (line.type === "LEAGUE") {
      return <Icon color='blue' className='cursorPointer' title="Dodaj uczestnika do turnieju" name="add" onClick={() => addMemberToLeagueAcc(line2.id, line.subtournament_id)} />
    } else {

    }


  }


  function getMembersLastCyclePlacesToTab(tmember_id, subtournament_id) {

    try {

      if (MembersLastCyclePlaces !== null && MembersLastCyclePlaces[subtournament_id] && MembersLastCyclePlaces[subtournament_id].length > 0) {

        var result = MembersLastCyclePlaces[subtournament_id].filter(obj => {
          return obj.tournament_member_id === tmember_id
        })
        if (result) {
          if (result[0].cycle_order_nr) {
            return <div title={"CYKL: " + result[0].cycle_name}>{result[0].cycle_order_nr}</div>
          } else {
            return "-"
          }

        } else {
          return 0;
        }
      }
      return "";
    } catch (e) {
      //console.log(e)
      return ""
    }
  }

  function addUsersFunc(tmpDatatmp, tournament_name) {
    setopenModalAddMembersToTournament({ open: true, data: { ...tmpDatatmp, tournament_name: tournament_name } })
  }

  function createPane(tmpDatatmp, pakietFree) {

    let panestmp = []

    if (tmpDatatmp && tmpDatatmp.subtournaments) {
      tmpDatatmp.subtournaments.map((line, i) => (
        line.parent_subtournament_id !== null ? "" :

          panestmp.push({
            menuItem: line.name,
            render: () => <Tab.Pane attached={false}>
              <div key={i} >


                <Button size='tiny' icon labelPosition='right' color='brown' fluid onClick={() => removeKat(line.subtournament_id, line.name)}>  <Icon name='trash' />KASOWANIE KATEGORII</Button>



                <Message icon size='mini'>
                  <Icon name={line.member_type === "USER" ? 'user' : 'users'} />
                  <Message.Content>
                    <Message.Header>Typ rozgrywek: {line.member_type_m}</Message.Header>
                    Zmień typ na   <Icon disabled={loadingMT} className='cursorPointer' onClick={() => changeMemberType(line.subtournament_id, line.member_type)} bordered inverted color='orange' name={line.member_type === "USER" ? 'users' : 'user'} title={line.member_type === "USER" ? 'Drużynowy' : 'Osobowy'} />
                  </Message.Content>
                </Message>

                <Grid stackable style={{ padding: 5 }} >

                  <Grid.Row >

                    <Grid.Column width={width < 1400 ? 16 : 10}>
                      <Message size='tiny'>
                        <p><Icon name="info circle" />Usunięcie uczestnika z rozgrywek działa normalnie w przypadku niezatwierdzonej struktury, jeżeli struktura rozgrywek jest zatwierdzona (np wygenerowana tabela lub drabinka). To kasowanie uczestnika jest możliwe tylko jeżeli zostanie zastąpiony nowym uczestnikiem.</p>
                      </Message>
                      <Segment basic style={{ padding: 0, textAlign: "right" }}>
                        <Icon className={tournamentJson.tournament_free === 2 ? 'cursorPointer tllink' : ""} title="IMPORTUJ UCZESTNIKÓW" bordered floated name="cloud upload" onClick={() => tournamentJson.tournament_free === 2 ? setopenMembers({ open: true, data: { subtournament_id: line.subtournament_id, member_type: line.member_type } }) : addToast("ZABLOKOWANE - Ta opcja jest dostępna tylko w pakiecie złotym", { appearance: 'warning' })} />
                        <Icon className='cursorPointer tllink' title="DRUKUJ" bordered floated name="print" onClick={handlePrintTabUsers} />
                        {saveCsvTab(line.members, line.name + "_uczestnicy.csv")}
                      </Segment>
                      <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                        <div ref={componentRefPrintUsers}>
                          <Table size='small' basic='very' celled striped compact unstackable >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                                <Table.HeaderCell >{line.member_type === "USER" ? 'UCZESTNIK' : 'DRUŻYNA'}</Table.HeaderCell>
                                {line.member_type === "USER" ? "" : <Table.HeaderCell width={3} style={{ lineHeight: "12px" }}>LISTA ZAWODNKÓW</Table.HeaderCell>}
                                <Table.HeaderCell style={{ lineHeight: "10px" }}>MIEJSCE <br /> <span style={{ fontSize: 9 }}>W CYKLU</span></Table.HeaderCell>
                                <Table.HeaderCell>EMAIL</Table.HeaderCell>
                                {tournamentJson.show_members_additional_info ? <Table.HeaderCell style={{ lineHeight: "12px" }} textAlign='center'>DODATKOWA INFORMACJA</Table.HeaderCell> : ""}
                                <Table.HeaderCell width={1} textAlign='center'>NumerTG</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign='center' singleLine>ZAPIS </Table.HeaderCell>
                                <Table.HeaderCell width={1} style={{ lineHeight: "10px" }} textAlign='center' singleLine>POTWIERDZENIE <br /> <span style={{ fontSize: 9 }}>EMAIL-A</span> </Table.HeaderCell>
                                {tournamentJson.confirm_members_by_organizer ? <Table.HeaderCell width={1} style={{ lineHeight: "10px" }} textAlign='center' singleLine>POTWIERDZENIE <br /> <span style={{ fontSize: 9 }}>ORGANIZATOR</span></Table.HeaderCell> : ""}
                                <Table.HeaderCell width={1} className='hiddenPrint'>PODMIANA</Table.HeaderCell>
                                <Table.HeaderCell width={1} className='hiddenPrint'></Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {line && line.members ?
                                line.members.map((line2, i) =>
                                  <Table.Row key={i}>
                                    <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                    <Table.Cell singleLine style={{ minWidth: 250, position: "relative" }}>{line2.elo_points && showEloP(tmpDatatmp.start_date, tmpDatatmp.stop_date) ? <span style={{ fontSize: 11, position: "absolute", top: -4, right: 2, color: "#767676" }}><span style={{ fontSize: 9 }}>{checkisglicko() ? "GLICKO" : "ELO"}:</span> {line2.elo_points} </span> : ""}<figure className='imgPlayer'><ShowImgAvatar urlpic={line2.img_file} mtype={tournamentJson ? tournamentJson.tournament_type : "0"} msize={'mini'} /><figcaption>{getlinkplayerprofile(line2.member_id, line2.name)} <Icon name='edit' className='cursorPointer hiddenPrint' onClick={() => setopenChangeUserData({ open: true, data: line2, subTournamentId: line.subtournament_id, subTurnamentType: line.member_type, edit: 1 })} /></figcaption></figure> </Table.Cell>
                                    {line.member_type === "USER" ? "" : <Table.Cell ><Button fluid
                                      onClick={() => setopenModalMembersTeams({ open: true, data: { subtournament_id: line.subtournament_id, row: line2 } })}
                                      size='mini'>Lista zawodników {line2.members_list ? line2.members_list.length : "n/n"}</Button></Table.Cell>}
                                    <Table.Cell textAlign='center' width={1}>{getMembersLastCyclePlacesToTab(line2.id, line.subtournament_id)}</Table.Cell>

                                    <Table.Cell>
                                      {line2.email ? line2.email :
                                        isDemo || line2.lictl === null ? "" :
                                          <div style={{ cursor: "pointer" }} title={"Dodaj adres email"} onClick={() => addMemberMail(line2)}> <Icon.Group style={{ fontSize: 16 }} color="blue">
                                            <Icon name='mail' color="blue" />
                                            <Icon corner name='add' color="blue" />
                                          </Icon.Group></div>}
                                    </Table.Cell>
                                    {tournamentJson.show_members_additional_info ? <Table.Cell style={{ lineHeight: "12px" }} textAlign='center'> <Markup content={line2.additional_info ? line2.additional_info : ""} /> <Icon name='edit' className='cursorPointer hiddenPrint' onClick={() => setopenChangeUserData({ open: true, data: line2, subTournamentId: line.subtournament_id, subTurnamentType: line.member_type, edit: 2 })} /></Table.Cell> : ""}
                                    <Table.Cell textAlign='center'>{line2.lictl}</Table.Cell><Table.Cell textAlign='center'>{line2.added_by}</Table.Cell><Table.Cell textAlign='center'>{getColorComfirm(line2.not_confirm, line2.added_by, line2, line.subtournament_id)}</Table.Cell>
                                    {tournamentJson.confirm_members_by_organizer ? <Table.Cell textAlign='center'> <Icon color={line2.organizer_confirmed ? "green" : "red"} name={line2.organizer_confirmed ? "check" : "x"} />{' '} <Dropdown
                                      inline
                                      options={organizer_confirmed_def}
                                      value={line2.organizer_confirmed}
                                      onChange={(e, d) => setFlagMemberOrganizerConfirmed(line2.id, tournamentJson.tournament_id, d.value)}
                                    /></Table.Cell> : ""}
                                    <Table.Cell className='hiddenPrint' textAlign='center' > {line2.member_in_matches === 0 ? <> <Icon className='cursorPointer' size='large' title="Podmiana uczestnika" name='retweet' color='orange' onClick={() => changeUsersTournamentQ(line.subtournament_id, line2, line.members)} /> {addNewMemberToGroup(line2, line)} </> : <Icon disabled title="Podmiana uczestnika zablokowana, uczestnik jest juz w rozgrywkach" name='retweet' />}</Table.Cell><Table.Cell className='hiddenPrint' textAlign='center'> {line2.member_in_played_matches === 0 ? <Icon className='cursorPointer' title="Kasowanie uczestnika" name='trash' onClick={() => removeUsersTournamentQ(line.subtournament_id, line2.id, line2.name)} /> : <Icon disabled title="Kasowanie uczestnika zablokowane, uczestnik posiada rozegrane pojedynki w tych rozgrywkach" name='trash' />}</Table.Cell>
                                  </Table.Row>
                                )
                                : <Table.Row></Table.Row>}

                            </Table.Body>


                          </Table>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={width < 1400 ? 16 : 6}>

                      <Segment>
                        <Label style={{ marginBottom: 10 }} color='red' ribbon>
                          <Icon name='add' /> Zapisz uczestnika:
                        </Label>
                        {tmpDatatmp && tmpDatatmp.register_online === 1 ?
                          <ChangeLimitMembers tournament_id={tmpDatatmp.tournament_id} membersLimit={line.members_limit} subTournamentRow={line} />
                          : ""}

                        {tmpDatatmp && tmpDatatmp.tournament_free !== 2 && Number(line && line.members ? line.members.length : 0) >= getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free) ?
                          <Message size='small' negative>
                            <Message.Header>Brak możliwości dodania uczestnika do rozgrywek</Message.Header>
                            <p>Limit {getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} uczestników został osiągnięty, proszę zmienić na wyższy pakiet <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} /></p>
                          </Message>
                          :
                          <>
                            {tmpDatatmp && tmpDatatmp.tournament_free !== 2 ?
                              <Progress value={line && line.members ? line.members.length : 0} className={line && line.members && line.members.length > 2 ? 'tgprogress' : 'tgprogressp'} total={getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} progress='ratio' error>
                                Limit {getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} uczestników <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                              </Progress>
                              : ''}

                            <MyTournamentAddUsers show_members_additional_info={tmpDatatmp.show_members_additional_info} subTournamentRow={line} google_ads={tmpDatatmp.google_ads} tournamentFree={tmpDatatmp.tournament_free} subTournamentId={line.subtournament_id} subTurnamentType={line.member_type} registrationTournament={false} />
                          </>
                        }


                      </Segment>
                      {tmpDatatmp && tmpDatatmp.tournament_free === 0 ? "" :
                        <Segment>
                          <Label style={{ marginBottom: 0 }} color='red' ribbon>
                            <Icon name='search' /> Wyszukaj uczestnika:
                          </Label>

                          {tmpDatatmp && tmpDatatmp.tournament_free !== 2 && Number(line && line.members ? line.members.length : 0) >= getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free) ?
                            <Message negative>
                              <Message.Header>Brak możliwości dodania uczestnika do rozgrywek</Message.Header>
                              <p>Limit {getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} uczestników został osiągnięty, proszę zmienić na wyższy pakiet</p>
                            </Message>
                            :
                            <>
                              {tmpDatatmp && tmpDatatmp.tournament_free !== 2 ?
                                <Progress value={line && line.members ? line.members.length : 0} className={line && line.members && line.members.length > 2 ? 'tgprogress' : 'tgprogressp'} total={getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} progress='ratio' error>
                                  Limit {getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} uczestników
                                </Progress>
                                : ''}
                              <Form style={{ marginTop: 10 }} size='tiny' onSubmit={findMembers} >
                                <Form.Group widths='equal'>

                                  <Form.Field
                                    width={12}
                                    control={Input}
                                    required
                                    placeholder='Email uczestnika lub numer TG'
                                    value={searchMember.search}
                                    onChange={(e, data) => setsearchMember({ ...searchMember, search: data.value })}

                                  />
                                  <Form.Button size='tiny' loading={loadingsearchMember} disabled={loadingsearchMember || isDemo} fluid>SZUKAJ</Form.Button>
                                </Form.Group>


                              </Form>

                              {isDemo ?
                                <Message size='mini'>
                                  <Message.Header><Icon name="lock" color="red" /> Pole szukaj zablokowane</Message.Header>
                                  <p>Pole zablokowane na koncie testowym</p>
                                </Message>
                                : ''}
                              {searchMember.resultSearch !== null ?
                                searchMember.resultSearch.member_name || searchMember.resultSearch.lictl ?
                                  <Segment>
                                    <Icon name="user" />{searchMember.resultSearch.member_name}  <Icon name="address book" />NTG:[{searchMember.resultSearch.lictl}]
                                    <Message size='tiny' color='grey'>Ważne! - zakaz dodawania uczestników w celach testowych do rozgrywek bez ich akceptacji.</Message>
                                    <Form.Button size='tiny' onClick={() => addFindMembersToT(line.subtournament_id)} fluid>DODAJ DO ROZGRYWEK</Form.Button>
                                  </Segment>

                                  :
                                  <Message size='tiny' color='red'>Brak szukanego uczestnika w systemie</Message>


                                : ""}
                            </>


                          }
                        </Segment>
                      }
                      <Segment>
                        <Label style={{ marginBottom: 0 }} color='red' ribbon>
                          <Icon name='add' /> Dodaj uczestników:
                        </Label>
                        {tmpDatatmp && tmpDatatmp.tournament_free !== 2 && Number(line && line.members ? line.members.length : 0) >= getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free) ?
                          <Message size='small' negative>
                            <Message.Header>Brak możliwości dodania uczestnika do rozgrywek</Message.Header>
                            <p>Limit {getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 && line.type !== "LEAGUE" ? 15 : authContext.user.subscription_info.subscription_extended === -1 && line.type === "LEAGUE" ? -1 : tmpDatatmp.tournament_free)} uczestników został osiągnięty, proszę zmienić na wyższy pakiet <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} /></p>
                          </Message>
                          :
                          <>
                            <div style={{ marginTop: 10 }} >  Dodaj uczestników na podstawie wcześniejszych turniejów.
                              <Button style={{ marginTop: 10 }} size='small' onClick={() => tmpDatatmp && tmpDatatmp.tournament_free === 0 ? addToast("ZABLOKOWANE - Ta opcja jest dostępna tylko w pakiecie srebrnym lub złotym", { appearance: 'warning' }) : addUsersFunc(line, tmpDatatmp.tournament_name)} fluid>DODAJ UCZESTNIKÓW</Button></div>
                          </>}
                      </Segment>


                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <br />

                <Label style={{ marginBottom: 30 }} color='red' ribbon>
                  <Icon name='sticky note' /> Rozgrywki
                </Label>
                {line.struct_confirmed === 1 ?
                  <> {/* <Label style={{ cursor: "pointer" }} onClick={() => (changeFullscreen())}><Icon name="window maximize" />FULLSCREEN TYLKO TURNIEJ</Label>

                    {onlyCategory ? "" :
                      <>
                        {lockedCheckB() === false ? <ReactToPrint
                          trigger={() => (
                            <Label style={{ cursor: "pointer" }} >
                              {lockedCheck()} DRUKOWANIE TURNIEJU <Icon link title="Drukowanie turnieju" name="print" />

                            </Label>
                          )}
                          content={() => {
                            return elRefsPrint[i].current;
                          }}
                        /> : <Label  >
                          {lockedCheck()} DRUKOWANIE TURNIEJU <Icon title="Drukowanie turnieju" name="print" />

                        </Label>}
                      </>
                    }
*/}


                    <Label style={{ cursor: "pointer" }} onClick={() => loadingdelt ? "" : (unconfirmSubtournamentStruct(line.subtournament_id))}>{loadingdelt ? <Icon name="spinner" loading /> : <Icon name="trash" />}KASUJ ZATWIERDZONY SYSTEM ROZGRYWKI i WYBIERZ PONOWNIE</Label>
                  </>
                  : ""}
                {line.struct_confirmed !== 1 ?
                  <div >
                    <StepCreateTournament MembersLastCyclePlaces={MembersLastCyclePlaces && MembersLastCyclePlaces[line.subtournament_id] ? MembersLastCyclePlaces[line.subtournament_id] : null} tournament_free={tmpDatatmp.tournament_free} third_place_encounter={line && line.third_place_encounter ? line.third_place_encounter : 0} ttype={line && line.type ? line.type : ""} members={line.members ? line.members.length : 0} tournamentJson={line.tournament_json} tournamentGroups={line.groups} struct_confirmed={line.struct_confirmed} subtournament_id={line.subtournament_id} />
                  </div>
                  : <>



                    <div ref={componentRefPrintST}>

                      <Grid stackable>
                        <Grid.Row>
                          <Grid.Column width={12} verticalAlign="middle">
                            <Header as='h3' >
                              <Header.Content>
                                <Icon name='winner' />  {tmpDatatmp ? tmpDatatmp.tournament_name : "?"}
                                <Header.Subheader><Icon name='calendar' /> {tmpDatatmp ? changeFormatDateTimeBS(tmpDatatmp.start_date) : "?"} {<Icon name="circle" />}{line.name}
                                  <Icon className='cursorPointer tllink hiddenPrint' style={{ marginLeft: 10 }} title="DRUKUJ" bordered floated name="print" onClick={handlePrintTabST} />
                                </Header.Subheader>

                              </Header.Content>
                            </Header>

                          </Grid.Column>
                          <Grid.Column width={4} textAlign="right" only='large screen'>
                            <Image alt="logo" floated='right' style={{ width: 200 }} centered src={LogoTeamLigaGenerator} />
                          </Grid.Column>

                        </Grid.Row>

                      </Grid>



                      {tournamentJson && tournamentJson.tournament_free === 0 && tournamentJson.google_ads === 1 && Number(process.env.REACT_APP_IN_GOOGLEROFF) === 0 ?
                        <Message className='hiddenPrint'>
                          <Message.Header><Icon name="adversal" />Reklama Google</Message.Header>

                          <AdSense.Google
                            client='ca-pub-7406426760252636'
                            slot='5932588222'
                            style={{ display: 'block', textAlign: "center" }}
                            layout='in-article'
                            format='fluid'
                          />

                        </Message>
                        : ""}

                      {line.additional_info !== null ? <Message className='hiddenPrint' icon size='mini'>
                        <Icon name='info circle' />
                        <Message.Content>
                          <Message.Header>Informacja na temat rozgrywek</Message.Header>
                          <Markup content={line.additional_info} />
                        </Message.Content>
                      </Message> : ""}

                      {tmpDatatmp.points_calc_system_id === 5 ? <Button fluid className='hiddenPrint' style={{ marginTop: 15 }} size='mini' onClick={() => getTournamentData(tmpDatatmp.tournament_id)}>
                        Odśwież wyniki rozgrywek, jeżeli jakieś pojedynki były rozgrywane poza tym oknem
                      </Button> : ""}


                      {line.groups.length > 0 && line.type === "GROUP" ? <>

                        <BracketGroupsEWE subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} linkTournament={tmpDatatmp.tournament_id} struct_confirmed={line ? line.struct_confirmed : -1} currentSubtournament={line ? line : null} remoteGroups={line ? line.groups : []} onlyView={0} tournamentId={line.subtournament_id} subtournaments={tmpDatatmp ? tmpDatatmp.subtournaments : []} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}
                      {line.groups.length > 0 && line.type === "GROUPS_CUP" ? <>
                        {width < 1400 ? "" :
                          <Message className='hiddenPrint' size='mini'>
                            <Checkbox toggle label={authContext.onlyOneColumn ? "Aktywny widok w jednej kolumnie, zmień widok na dwie kolumny" : "Aktywny widok w dwóch kolumnach, zmień widok na jedną kolumnę"} onChange={(e, d) => authContext.changeOnlyOneColumn(d.checked ? false : true)} checked={authContext.onlyOneColumn ? false : true} />
                          </Message>}
                        <BracketGroups subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} linkTournament={tmpDatatmp.tournament_id} struct_confirmed={line ? line.struct_confirmed : -1} remoteGroups={line ? line.groups : []} onlyView={0} tournamentId={line.subtournament_id} subtournaments={tmpDatatmp ? tmpDatatmp.subtournaments : []} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}
                      {line.type !== "GROUP" && line.type !== "SWISS" && line.type !== "SWISS_CUP" && line.type !== "LEAGUE" ? <>
                        <BracketGen isPanelWrappert={false} subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} ttname={""} third_place_encounter={line && line.third_place_encounter ? line.third_place_encounter : 0} ttype={line && line.type ? line.type : ""} totalMembers={line && line.members ? line.members.length : 0} remoteJson={line && line.tournament_json ? line.tournament_json : {}} onlyView={editBracket ? 0 : 1} tournamentId={line.subtournament_id} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}
                      {line.type === "SWISS" ? <>
                        {width < 1400 ? "" :
                          <Message className='hiddenPrint' size='mini'>
                            <Checkbox toggle label={authContext.onlyOneColumn ? "Aktywny widok w jednej kolumnie, zmień widok na dwie kolumny" : "Aktywny widok w dwóch kolumnach, zmień widok na jedną kolumnę"} onChange={(e, d) => authContext.changeOnlyOneColumn(d.checked ? false : true)} checked={authContext.onlyOneColumn ? false : true} />
                          </Message>
                        }
                        <BracketSWISS subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} totalMembers={line && line.members ? line.members.length : 0} tournament_id={tmpDatatmp.tournament_id} remoteJson={line ? line : {}} onlyView={0} subtournament_id={line.subtournament_id} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}
                      {line.type === "SWISS_CUP" ? <>
                        {width < 1400 ? "" :
                          <Message className='hiddenPrint' size='mini'>
                            <Checkbox toggle label={authContext.onlyOneColumn ? "Aktywny widok w jednej kolumnie, zmień widok na dwie kolumny" : "Aktywny widok w dwóch kolumnach, zmień widok na jedną kolumnę"} onChange={(e, d) => authContext.changeOnlyOneColumn(d.checked ? false : true)} checked={authContext.onlyOneColumn ? false : true} />
                          </Message>
                        }
                        <BracketSWISSCup subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} totalMembers={line && line.members ? line.members.length : 0} tournament_id={tmpDatatmp.tournament_id} remoteJson={line ? line : {}} onlyView={0} subtournament_id={line.subtournament_id} subtournaments={tmpDatatmp ? tmpDatatmp.subtournaments : []} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}
                      {line.type === "LEAGUE" ? <>
                        <BracketLeague subT_type={line.type_m} tournament_free={tournamentJson && tournamentJson.tournament_free ? tournamentJson.tournament_free : 0} systemId={tmpDatatmp.points_calc_system_id} totalMembers={line && line.members ? line.members.length : 0} tournament_id={tmpDatatmp.tournament_id} remoteJson={line ? line : {}} onlyView={0} subtournament_id={line.subtournament_id} pointsCalcSystemId={tmpDatatmp.points_calc_system_id} />
                      </> : ""}



                      <Grid stackable divided style={{ marginTop: 20 }}>
                        <FinalTableCalcPointsSystems subtournament_id={line.subtournament_id} final_table_calc_points_system_id={final_table_calc_points_system_id} setfinal_table_calc_points_system_id={setfinal_table_calc_points_system_id} />


                        <Grid.Row style={{ marginTop: 10 }} >
                          <Grid.Column width={6} >
                            <Button className='hiddenPrint' fluid size='tiny' color='grey' onClick={() => finishTournament(line.final_table.length, line.subtournament_id, false, tmpDatatmp.possible_members_upgrade, tmpDatatmp.tournament_id)} >ZAKOŃCZ ROZGRYWKI, WYGENERUJ TABELE KOŃCOWĄ</Button>

                          </Grid.Column>
                          <Grid.Column width={9} >
                            {returnButtonFTSM(line, tmpDatatmp.possible_members_upgrade, tmpDatatmp.tournament_id)}
                          </Grid.Column>
                          <Grid.Column width={1} textAlign='right'>
                            <Icon bordered inverted color='grey' className='hiddenPrint cursorPointer' name="trash" title="Skasowanie tabeli końcowej, nie wpływa to na wynik" onClick={() => finishTournamentDel(line.subtournament_id)} />

                          </Grid.Column>
                        </Grid.Row>
                      </Grid>



                      {line && line.final_table.length > 0 && line.subtournament_finished ?
                        <>

                          <Divider className='hiddenPrint' />
                          <div className='page-break'></div>


                          <Label color='red' ribbon >
                            <Icon name="table" />WYNIKI - tabela końcowa
                          </Label>

                          {line.type === "LEAGUE" || line.type === "SWISS" || line.type === "GROUP" ?
                            getInfoTab()
                            : ""}

                          <Grid stackable divided>
                            <Grid.Row  >

                              {tmpTotalPointInfoArr ?
                                <Grid.Column width={4} >
                                  <Sticky context={contextRef} offset={2} active={width < 600 ? false : true}>
                                    <EditRowFinalTable myEditRow={tmpTotalPointInfoArr} />
                                    <Button fluid onClick={() => settmpTotalPointInfoArr(null)}>
                                      ZAMKNIJ
                                    </Button>
                                  </Sticky>
                                </Grid.Column> : ""}

                              <Grid.Column width={tmpTotalPointInfoArr ? 12 : 16} >
                                <div ref={contextRef}>

                                  <Message className='hiddenPrint' size='mini' style={{ marginTop: 20 }}>
                                    <b>Puntakcje</b> wygenerowana automatycznie <i>(zobacz, Instrukcje - Punktacje turniejowe)</i> można ręcznie zmienić, klikajać ikonę <Icon name="edit" size='small' color='orange' />przy danym rekordzie.
                                  </Message>

                                  <Segment className='hiddenPrint' basic style={{ margin: 0, padding: 0, textAlign: "right" }}>
                                    <Icon className='cursorPointer tllink' title="DRUKUJ" bordered floated name="print" onClick={handlePrintEndTab} />
                                    {saveCsvTab(line.final_table, line.name + "_wyniki.csv")}
                                  </Segment>
                                  <div ref={componentRefPrintEndTab}>
                                    <GetTableScore isDarkTheme={authContext.darkMode} systemId={tmpDatatmp.points_calc_system_id} onlyView={0} subtournament_id={line.subtournament_id} tournament_free={tmpDatatmp.tournament_free} TableData={line.final_table} typeT={line.type} league_info={line.league_info} tmpTotalPointInfoArr={tmpTotalPointInfoArr} settmpTotalPointInfoArr={settmpTotalPointInfoArr} />
                                  </div>
                                </div>
                              </Grid.Column>

                            </Grid.Row>
                          </Grid>


                        </> : <Message className='hiddenPrint' size='tiny' info>
                          <Message.Header>Brak tabeli z wynikami</Message.Header>
                          <p>Po zakończonych meczach, wygeneruj tabele z wynikami. klikając "ZAKOŃCZ ROZGRYWKI, WYGENERUJ TABELE KOŃCOWĄ"</p>
                        </Message>

                      }
                    </div>

                  </>
                }


              </div>

            </Tab.Pane>,
          })


      ))
    }

    setpanes(panestmp)
  }

  function openModal(id) {
    // console.log("OPEN")
    setisOpen(true)
    setphotoIndex(id)
  }




  function getValueDataInput(locked, nameInput, valueInput, typeInput, dataSendName, tournamentId) {
    if (tournamentJson.tournament_free === 0 && locked === 1) {
      addToast("ZABLOKOWANE - Ta opcja jest dostępna tylko w pakiecie srebrnym lub złotym", { appearance: 'warning' });
      return
    }
    authContext.changeModalInfo({ nameModal: "ChangeTurnament", name: nameInput, value: valueInput, itype: typeInput, dataSendName: dataSendName, tournamentId: tournamentId, jsonBody: tournamentJson })
  }


  function changeUsersTournamentQ(subtournament_id, memberInfoCurrent, members) {
    ConnectWS("/generator/getSubtournamentMembersInMatches", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {

        let arrtmp = [];
        arrtmp.length = 0;
        if (response.data && response.data.length > 0) {
          response.data.forEach((data, i) => {
            arrtmp.push({
              key: i,
              text: data.member_name,
              value: data,
              content: data.member_name
            })
          })
          setChangeUsertab(arrtmp)
        }

      } else {
        setChangeUsertab([])

      }



    });



    setformChangeUser({ ...formChangeUser, memberInfoCurrent: memberInfoCurrent, subtournament_id: subtournament_id, memberInfoOld: null })
    setopenChangeUser(true);

  }

  function removeUsersTournamentQ(subtournament_id, tournament_member_id, name) {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować uczestnika ' + name + '?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeUsersTournament(subtournament_id, tournament_member_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function removeUsersTournament(subtournament_id, tournament_member_id) {
      ConnectWS("/generator/removeMemberFromTournament", authContext.token, { subtournament_id: subtournament_id, tournament_member_id: tournament_member_id }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }



      });
    }




  }

  function getTournamentData(tournament_id) {

    setloading(true)
    ConnectWS("/generator/getTournamentData", authContext.token, { tournament_id: tournament_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        settournamentJson(response.data)
        if (LiveIcon(response.data.start_date, response.data.stop_date)) {
          authContext.changeLiveIcon(1)
        } else {
          authContext.changeLiveIcon(0)
        }
        setlimitUsers(getLimitUsersPackage(authContext.user.subscription_info.subscription_extended === -1 ? 15 : response.data.tournament_free))
        createPane(response.data, response.data.tournament_free)
        /*
        setElRefsPrint(elRefsPrint => (
          Array(response.data.subtournaments.length).fill().map((_, i) => elRefsPrint[i] || createRef())
        ));
        */
        let pageName = "Rozgrywki - " + response.data.tournament_name
        changePageName(pageName)




        if (response.data.tournament_description) {
          setrte(response.data.tournament_description)
        }


        getMembersLastCyclePlaces(tournament_id)

      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)

    });
  }



  function lockedCheck(pakietFree) {
    if ((tournamentJson && tournamentJson.tournament_free === 0) || pakietFree === 0) {
      return <List.Icon className='hiddenPrint' name="lock" color="red" title="ZABLOKOWANE - funkcja dostępna tylko w pakiecie srebrnym lub złotym" />
    } else {
      return <List.Icon className='hiddenPrint' name="unlock alternate" />
    }
  }

  function accMemberTournament(row, subtournament_id) {
    console.log(row)
    let tournament_member_id = row.id
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zatwierdzić uczestnika ' + row.name + ' w rozgrywkach',
      buttons: [
        {
          label: 'Tak',
          onClick: () => confirmTournamentMemberByOrganizer(tournament_member_id, subtournament_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function confirmTournamentMemberByOrganizer(tournament_member_id, subtournament_id) {
      ConnectWS("/generator/confirmTournamentMemberByOrganizer", authContext.token, { subtournament_id: subtournament_id, tournament_member_id: tournament_member_id }, function (response, logout) {
        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }

      });
    }
  }

  function removeKat(subtournament_id, subtournament_name) {

    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować kategorię ' + subtournament_name,
      buttons: [
        {
          label: 'Tak',
          onClick: () => removeKatq(subtournament_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function removeKatq(subtournament_id) {
      ConnectWS("/generator/removeSubtournament", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {
        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }

      });
    }
  }


  function getColorComfirm(nnot_confirm, madded_by, row, subtournament_id) {

    if (madded_by === "użytkownik") {
      if (nnot_confirm === true) {
        return <><Icon name="cancel" color="red" title="Uczestnik nie potwierdził maila z uczestnictwem w rozgrywkach" /><Icon className='cursorPointer hiddenPrint' onClick={() => accMemberTournament(row, subtournament_id)} color="orange" name="plus" title="Potwierdź uczestnika" /></>
      } else if (nnot_confirm === false) {
        return <Icon name="checkmark" color="green" title="Uczestnik potwierdził uczestnictwo w rozgrywkach" />
      }

    } else if (madded_by === "organizator") {
      return <Icon.Group size='small' title="Uczestnik dodany przez organizatora">
        <Icon name='checkmark' color="green" />
        <Icon corner name='checkmark' color="green" />
      </Icon.Group>
    } else {
      return ""
    }
  }




  function finishTournament(final_table_lenght, subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id) {
   
    if (final_table_lenght > 1) {
      confirmAlert({
        title: 'Proszę potwierdzić',
        message: 'Czy chcesz wygenerować ponownie tabele końcową?',
        buttons: [
          {
            label: 'Tak',
            onClick: () => finishTournamentACCPre(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id)
          },
          {
            label: 'Nie',
            onClick: () => ''
          }
        ]
      });
    } else {
      finishTournamentACCPre(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id)
    }


    function finishTournamentACCPre(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id) {
     
      if (sendMemberEmailNotifications===true) {

        setTimeout(() => {
          confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz wysłać maila z podsumowaniem do uczestników?',
            buttons: [
              {
                label: 'Tak',
                onClick: () => finishTournamentACC(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id)
              },
              {
                label: 'Nie',
                onClick: () => ''
              }
            ]
          });
        }, 100);


      } else {
        finishTournamentACC(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id)
      }

      function finishTournamentACC(subtournament_id, sendMemberEmailNotifications, possible_members_upgrade, tournament_id) {

        ConnectWS("/generator/generateSubtournamentResultTable", authContext.token, { subtournament_id: subtournament_id, sendMemberEmailNotifications: sendMemberEmailNotifications, final_table_calc_points_system_id: final_table_calc_points_system_id === 0 ? null : final_table_calc_points_system_id }, function (response, logout) {

          if (logout === 1) {
            addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
            setTimeout(() => {
              setAuth(false);
            }, 4000);

          } else if (response && response.status && Number(response.status) === 200) {

            authContext.changeRefreshView(authContext.refreshView + 1)
            if (possible_members_upgrade) {
              authContext.changeModalInfo({ nameModal: "generateHotPayDataUpgradeTournamentMembersToLictl", name: "Jeszcze jeden krok...", value: tournament_id, viewClickOff: true })
            } else {
              addToast(response.data.message, { appearance: 'success' });
            }

            //authContext.changeModalInfo({ nameModal: "buyCoffeQuestions", name: "Jeszcze jeden krok...", value: "" })
          } else {

            if (logout === 101) {
              addToast(response, { appearance: 'info' });
            } else if (logout === 102) {
              addToast(response, { appearance: 'warning' });
            } else {
              addToast(response, { appearance: 'error' });
            }
            //cons

          }


        });
      }
    }
  }



  function finishTournamentDel(subtournament_id) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować tabelę końcową?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => delSubtournamentResultTable(subtournament_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function delSubtournamentResultTable(subtournament_id) {
      ConnectWS("/generator/delSubtournamentResultTable", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //cons

        }

      });
    }


  }



  //const componentRef = useRef();



  //const handlePrintTab = useReactToPrint({ content: () => elRefsPrint[0].current });


  //drukowanie listy zawodników
  const componentRefPrintUsers = useRef();
  const handlePrintTabUsers = useReactToPrint({
    content: () => componentRefPrintUsers.current,
  });

  //drukowanie system turniejowy
  const componentRefPrintST = useRef();
  const handlePrintTabST = useReactToPrint({
    content: () => componentRefPrintST.current,
  });


  // drukowanie tabeli koncowej
  const componentRefPrintEndTab = useRef();
  const handlePrintEndTab = useReactToPrint({
    content: () => componentRefPrintEndTab.current,
  });



  const findMembers = e => {
    e.preventDefault();
    setloadingsearchMember(true)
    let obj = null

    if (searchMember.search.includes("@")) {
      obj = { email: searchMember.search }
    } else {
      obj = { lictl: searchMember.search }
    }
    ConnectWS("/generator/findMember", authContext.token, obj, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
        setloadingsearchMember(false)
      } else if (response && response.status && Number(response.status) === 200) {
        //setRowDataTournament(response.data)
        setloadingsearchMember(false)
        if (response.data.length > 0) {
          setsearchMember({ ...searchMember, resultSearch: response.data[0] })
        } else {
          setsearchMember({ ...searchMember, resultSearch: "error" })
          //addToast("Nie znaleziono szukanego uczestnika", { appearance: 'error' });
        }

      } else {
        setloadingsearchMember(false)
        setsearchMember({ ...searchMember, resultSearch: null, search: null })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });



  }

  function addFindMembersToT(subtournament_id) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz dodać uczestnika ' + searchMember.resultSearch.member_name + ' do rozgrywek ?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => addFindMembersToTq(subtournament_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });

    function addFindMembersToTq(subtournament_id) {
      let member_id = searchMember.resultSearch.member_id
      ConnectWS("/generator/addMemberToTournament", authContext.token, { subtournament_id: subtournament_id, member_id: member_id, limit_users: limitUsers }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);
        } else if (response && response.status && Number(response.status) === 200) {
          //setRowDataTournament(response.data)
          addToast(response.data.message, { appearance: 'success' });
          setsearchMember({ ...searchMember, resultSearch: null, search: "" })
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {
          setsearchMember({ ...searchMember, resultSearch: null, search: "" })
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //console.log(response);
        }
        setloadingsearchMember(false)


      });
    }

  }
  function unconfirmSubtournamentStruct(subtournament_id) {

    setloadingdelt(true)
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz skasować zatwierdzoną strukturę rozgrywek?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => unconfirmSubtournamentStructacc(subtournament_id)
        },
        {
          label: 'Nie',
          onClick: () => setloadingdelt(false)
        }
      ]
    });

    function unconfirmSubtournamentStructacc(subtournament_id) {

      setloadingdelt(true)
      ConnectWS("/generator/unconfirmSubtournamentStruct", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

        if (logout === 1) {
          addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
          setTimeout(() => {
            setAuth(false);
          }, 4000);

        } else if (response && response.status && Number(response.status) === 200) {
          setopenChangeUser(false)
          addToast(response.data.message, { appearance: 'success' });
          authContext.changeRefreshView(authContext.refreshView + 1)
        } else {

          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
          //cons
          setopenChangeUser(false)
        }
        setloadingdelt(false)

      });
    }


  }



  function replaceMemberInTournament() {
    ConnectWS("/generator/replaceMemberInTournament", authContext.token, { formChangeUser }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        setopenChangeUser(false)
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeRefreshView(authContext.refreshView + 1)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //cons
        setopenChangeUser(false)
      }

    });

  }

  function retDefInfo() {
    return <span style={{ color: "red" }}>Brak podanych informacji</span>
  }




  return (
    <div style={{ padding: tournamentIdUP ? 20 : 1 }}> {isOpen && (
      <Lightbox
        mainSrc={photoIndex}
        onCloseRequest={() => setisOpen(false)}
      />
    )}
      {onlyCategory ? "" :
        <>
          {fullscreen === false ? <>
            {tournamentIdUP ? "" :
              <>
                {isDemo ? <TimerTournamentDemo tournament_create_time={tournamentJson ? tournamentJson.tournament_create_time : null} /> : ""}
                <Header as='h5' className='cursorPointer' textAlign='center' onClick={() => authContext.changeView({ myView: "MyTournaments" })}>
                  <Icon name='backward' />
                  <Header.Content>Powrót do Moje rozgrywki</Header.Content>
                </Header>
                <Divider />
              </>
            }
            <Segment loading={loading}>

              <Grid stackable  >
                <Grid.Row>
                  <Grid.Column width={13}>
                    <Header as='h3' style={{ lineHeight: "20px" }}  >
                      {tournamentJson && tournamentJson.charity_type != null && tournamentJson.charity_type > 0 ?
                        <div id="heart" >
                          <img class="bottom" alt="Turniej charytatywny" title='Turniej charytatywny' src={process.env.REACT_APP_PUBLIC_URL + '/img/hearttg.png'} width="80px" />
                        </div>
                        : <Icon name='winner' />}
                      <Header.Content>
                        {tournamentJson ? tournamentJson.tournament_name : "?"}
                        {tournamentJson && tournamentJson.charity_type != null && tournamentJson.charity_type > 0 ?
                          <Header.Subheader style={{ fontSize: 15 }}>Turniej charytatywny</Header.Subheader>
                          : ""}
                        <Header.Subheader>Nazwa rozgrywek <Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Nazwa rozgrywek", tournamentJson ? tournamentJson.tournament_name : "?", "input", "tournament_name", tournamentId)} /></Header.Subheader>

                      </Header.Content>
                    </Header>

                  </Grid.Column>
                  <Grid.Column width={3}>
                    {authContext.user.subscription_info.subscription_active && authContext.user.subscription_info.subscription_extended >= 0 ?
                      <Label style={{ visibility: tournamentJson && tournamentJson.tournament_free > -1 ? "visible" : "hidden" }} color='purple' ribbon='right' >
                        <div style={{ fontSize: 10, marginBottom: 3 }}><strong>SUBSKRYPCJA {authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? <Icon name="add" /> : ""}AKTYWNA</strong></div>
                        <Divider style={{ margin: 5, padding: 0 }} />
                        <div style={{ fontSize: 13, marginBottom: 3 }}><strong>Pakiet rozgrywek</strong></div>
                        <div style={{ fontSize: 20 }}>{tournamentJson && tournamentJson.tournament_free === 1 ? <>{packageColor(2)}Srebrny</> : tournamentJson && tournamentJson.tournament_free === 2 ? <>{packageColor(3)}Złoty</> : <>{packageColor(1)}Brązowy</>}</div>
                      </Label>
                      : <Label style={{ visibility: tournamentJson && tournamentJson.tournament_free > -1 ? "visible" : "hidden" }} as={tournamentJson && tournamentJson.tournament_free !== 2 ? 'a' : ''} color={tournamentJson && tournamentJson.tournament_free === 0 ? 'brown' : tournamentJson && tournamentJson.tournament_free === 1 ? 'grey' : tournamentJson && tournamentJson.tournament_free === 2 ? 'yellow' : ''} ribbon='right' onClick={() => tournamentJson && tournamentJson.tournament_free !== 2 ? getValueDataInput(0, "Pakiet", tournamentJson ? tournamentJson.tournament_free : "?", "checkboxPakiet", "tournament_free", tournamentId) : ""}>
                        <div style={{ fontSize: 13, marginBottom: 3 }}><strong>Pakiet rozgrywek</strong> {tournamentJson && tournamentJson.tournament_free !== 2 ? <Icon className='cursorPointer' color="orange" name='edit' /> : ""}</div>
                        <div style={{ fontSize: 20 }}>{tournamentJson && tournamentJson.tournament_free === 1 ? <>{packageColor(2)}Srebrny</> : tournamentJson && tournamentJson.tournament_free === 2 ? <>{packageColor(3)}Złoty</> : <>{packageColor(1)}Brązowy</>}</div>
                      </Label>}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={width < 1000 ? 16 : 11} >

                    <Grid celled='internally' unstackable >
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Organizator</b> <Icon name="user" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Organizator", tournamentJson ? tournamentJson.organizer_name : "?", "input", "organizer_name", tournamentId)} />
                          {tournamentJson ? tournamentJson.organizer_name : "?"}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Start rozgrywek</b> <Icon name="calendar" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Start rozgrywek", tournamentJson ? tournamentJson.start_date : "?", "datapicker", "start_date", tournamentId)} />
                          {tournamentJson ? tournamentJson.start_date : "?"}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Koniec rozgrywek</b> <Icon name="calendar" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Koniec rozgrywek", tournamentJson ? tournamentJson.stop_date : "?", "datapicker", "stop_date", tournamentId)} />
                          {tournamentJson ? tournamentJson.stop_date : "?"}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Koniec Zapisów</b> <Icon name="calendar" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>  <Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(1, "Koniec Zapisów", tournamentJson ? tournamentJson.register_stop_date : "?", "datapicker", "register_stop_date", tournamentId)} />
                          {tournamentJson ? tournamentJson.register_stop_date : "?"}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Kontakt do organizatora</b> <Icon name="phone" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Kontakt tel do organizatora", tournamentJson ? tournamentJson.organizer_phone : "?", "InputNumber", "organizer_phone", tournamentId)} />
                          {tournamentJson && tournamentJson.organizer_phone ? tournamentJson.organizer_phone : retDefInfo()}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Kontakt do organizatora</b> <Icon name="mail" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Kontakt email do organizatora", tournamentJson ? tournamentJson.organizer_email : "?", "InputMail", "organizer_email", tournamentId)} />
                          {tournamentJson && tournamentJson.organizer_email ? tournamentJson.organizer_email : retDefInfo()}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Lokalizacja rozgrywek</b><Icon name="map marker" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>  <Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Lokalizacja rozgrywek", tournamentJson ? tournamentJson.place : "?", "InputPlace", "place", tournamentId)} />
                          {tournamentJson && tournamentJson.place ? getAddressLocationGoole(tournamentJson.place, false) : retDefInfo()}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Link do strony rozgrywek</b> <Icon name="linkify" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Link do strony rozgrywek", tournamentJson ? tournamentJson.url : "?", "input", "url", tournamentId)} />
                          {tournamentJson && tournamentJson.url ? <a target="_blank" rel="noopener noreferrer" href={tournamentJson ? tournamentJson.url : '?'}>{tournamentJson.url}</a> : retDefInfo()}
                          <br /><span style={{ fontSize: 12 }}>Przykład https://jakasstrona.pl</span>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Zapisy online</b> {lockedCheck()}</Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>
                          <Checkbox style={{ fontSize: 14 }} onChange={(e, d) => getValueDataInput(1, "Zapisy online", d.checked, "checkbox", "register_online", tournamentId)} checked={tournamentJson && tournamentJson.register_online === 1 ? true : false} toggle label={tournamentJson && tournamentJson.register_online === 1 ? "Zapisy online włączone" : "Zapisy online wyłączone"} />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Potwierdzenie uczestnictwa przez organizatora</b>{lockedCheck()}</Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>
                          <Checkbox style={{ fontSize: 14 }} onChange={(e, d) => getValueDataInput(1, "Potwierdzenie uczestnictwa przez organizatora", d.checked, "checkbox", "confirm_members_by_organizer", tournamentId)} checked={tournamentJson && tournamentJson.confirm_members_by_organizer === 1 ? true : false} toggle label={tournamentJson && tournamentJson.confirm_members_by_organizer === 1 ? "Potwierdzenie włączone" : "Potwierdzenie wyłączone"} />
                          <br /><span style={{ fontSize: 12, lineHeight: "12px" }}>Informacja o potwierdzeniu pojawi się na liscie uczestników - organizator zaznacza tak/nie</span>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right' style={{ lineHeight: "12px" }}><b>Dodatkowa informacja przy zapisach</b> {lockedCheck()} </Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left' style={{ lineHeight: "12px" }}><Checkbox style={{ fontSize: 14 }} onChange={(e, d) => getValueDataInput(1, "Dodatkowe pole przy zapisach uczestników", d.checked, "checkbox", "show_members_additional_info", tournamentId)} checked={tournamentJson && tournamentJson.show_members_additional_info === 1 ? true : false} toggle label={tournamentJson && tournamentJson.show_members_additional_info === 1 ? "Dodatkowe pole włączone" : "Dodatkowe pole wyłączone"} />
                          <br /><span style={{ fontSize: 12, lineHeight: "12px" }}>Przy zapisach uczestników pojawi się dodatkowe pole, będzie możliwość dodania informacji o roczniku uczestnika, przynależności klubowej itp.</span>
                        </Grid.Column>
                      </Grid.Row>
                      {/*
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Punktacja ELO</b> {lockedCheck()}</Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Checkbox style={{ fontSize: 14 }} disabled={tournamentJson && tournamentJson.tournament_name && tournamentJson.tournament_name.toUpperCase().includes("TESTOWY") ? true : false} onChange={(e, d) => getValueDataInput(1, "Punktacja ELO", d.checked, "checkbox", "tournament_elo", tournamentId)} checked={tournamentJson && tournamentJson.tournament_elo === 1 ? true : false} toggle label={tournamentJson && tournamentJson.tournament_elo === 1 ? "Punktacja ELO włączona" : "Punktacja ELO wyłączona"} />
                        </Grid.Column>
                      </Grid.Row>
                      */}

                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Rodzaj sportu</b> <Icon name="setting" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Rodzaj sportu", tournamentJson ? tournamentJson.points_calc_system_id : "?", "selectRow", "points_calc_system_id", tournamentId)} />
                          {tournamentJson ? tournamentJson.points_calc_system_name : "?"}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Punktacja w fazie grupowej</b> <Icon name="setting" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Punktacja w fazie grupowej", tournamentJson ? tournamentJson.points_rule : "?", "pointsRule", "points_rule", tournamentId)} />
                          {tournamentJson && tournamentJson.points_rule && tournamentJson.points_rule.length > 0 ?
                            <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                              <Table celled compact unstackable>
                                <Table.Header>
                                  <Table.Row textAlign='center'>
                                    <Table.HeaderCell>Priorytet</Table.HeaderCell>
                                    <Table.HeaderCell>Wynik pojedynku</Table.HeaderCell>
                                    <Table.HeaderCell>Wygrana</Table.HeaderCell>
                                    <Table.HeaderCell>Remis</Table.HeaderCell>
                                    <Table.HeaderCell>Przegrana</Table.HeaderCell>

                                  </Table.Row>
                                </Table.Header>

                                <Table.Body >
                                  {tournamentJson.points_rule.map((row, i) =>
                                    <Table.Row key={i} textAlign='center'>
                                      <Table.Cell width={1} textAlign='center'>{i + 1}</Table.Cell>
                                      <Table.Cell>{row.member1_score === null && row.member2_score === null ? "DOWOLNY" : row.member1_score === row.member2_score ? <>{row.member1_score}:{row.member2_score}</> : <>{row.member1_score}:{row.member2_score} lub {row.member2_score}:{row.member1_score}</>}</Table.Cell>
                                      <Table.Cell>{row.winner_score}</Table.Cell>
                                      <Table.Cell>{row.draw_score}</Table.Cell>
                                      <Table.Cell>{row.loser_score}</Table.Cell>
                                    </Table.Row>
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                            : ""}
                        </Grid.Column>
                      </Grid.Row>


                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Wideo z rozgrywek</b> <Icon name="video camera" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Video z rozgrywek", tournamentJson ? tournamentJson.video_data : "?", "video_data", "video_data", tournamentId)} />
                          Dodaj link do transmisji online lub nagrania [youtube,facebook,vimeo,i inne]
                        </Grid.Column>
                      </Grid.Row>

                      {tournamentJson && tournamentJson.tournament_free === 0 && Number(process.env.REACT_APP_IN_GOOGLEROFF) === 0 ?
                        <Grid.Row className='mp3'>
                          <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><Icon name="adversal" /> Reklama Google {tournamentJson && tournamentJson.google_ads === 1 ? <span style={{ color: "green", fontWeight: "bold" }}>Włączona</span> : ""}</Grid.Column>
                          <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>{tournamentJson && tournamentJson.google_ads === 1 ?
                            <span style={{ fontSize: 14 }} onClick={() => getValueDataInput(0, "Reklama Google", tournamentJson ? tournamentJson.google_ads : "?", "google_ads", "google_ads", props.ViewProps.tournamentId)} className='cursorPointer'><Icon color="orange" name='power off' /> Wyłącz reklamy google</span>
                            : <span style={{ color: "red", fontWeight: "bold" }}>Wyłączona</span>}</Grid.Column>
                        </Grid.Row>
                        : ""}
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Dodaj rozgrywki do swojej strony www </b> <Icon name="star" /></Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'><Icon className='cursorPointer' color="orange" name='edit' onClick={() => setopenIframe(true)} />Instrukcja</Grid.Column>
                      </Grid.Row>
                      <Grid.Row className='mp3'>
                        <Grid.Column className='mp3' mobile={8} tablet={6} computer={4} textAlign='right'><b>Dedykowana strona na TV lub rzutnik</b> {lockedCheck()}</Grid.Column>
                        <Grid.Column className='mp3' mobile={8} tablet={10} computer={12} textAlign='left'>
                          {getlinktournamentTV(tournamentJson.tournament_id, false)}
                          <br /><span style={{ fontSize: 12, lineHeight: "12px" }}>System turniejowy pojawi się dopiero po zatwierdzeniu struktury</span>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Divider />
                    <Card.Group style={{ marginBottom: 10 }} className='CardTournament' centered>
                      <Card color="grey" >
                        <Card.Content className='bgCardTournament1'>
                          <Card.Header ><List.Icon name="linkify" />Link do rozgrywek dla uczestników</Card.Header>
                        </Card.Content>
                        <Card.Content className='bgCardTournament2'>
                          <Card.Description><Icon name="linkify" color='grey' />
                            {getlinktournament(tournamentJson.tournament_id, false)}
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra className='bgCardTournament3'>
                          <GenQrCode url={"https://turniej-generator.pl/tg/" + numberToCharTG(tournamentJson.tournament_id)} name={tournamentJson ? tournamentJson.tournament_name : "?"} />
                        </Card.Content>
                      </Card>
                      {tournamentJson && tournamentJson.subtournaments ? tournamentJson.subtournaments.map((line, i) => (
                        line.parent_subtournament_id !== null ? "" :
                          <Card color="grey" key={i} >
                            <Card.Content className='bgCardTournament1'>
                              <Card.Header ><List.Icon name="linkify" />Link do rozgrywek dla uczestników do kategorii: {line.name}</Card.Header>
                            </Card.Content>
                            <Card.Content className='bgCardTournament2'>
                              <Card.Description><Icon name="linkify" color='grey' />
                                <a href={"/tsv/" + tournamentJson.tournament_id + "/" + line.subtournament_id} target="_blank" rel="noopener noreferrer"> {"turniej-generator.pl/tsv/" + tournamentJson.tournament_id + "/" + line.subtournament_id}</a>
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra className='bgCardTournament3'>
                              <GenQrCode url={"https://turniej-generator.pl/tsv/" + tournamentJson.tournament_id + "/" + line.subtournament_id} name={tournamentJson ? tournamentJson.tournament_name + "," + line.name : "?"} />
                            </Card.Content>
                          </Card>

                      )) : ""}



                    </Card.Group>

                  </Grid.Column>
                  <Grid.Column width={width < 1000 ? 16 : 5} style={{ backgroundColor: width < 1000 ? "#f7f7f7" : "#f7f7f7", padding: 20 }}>
                    {tournamentJson && tournamentJson.potential_charity === 1 && tournamentJson.charity_type === null ?
                      <Button fluid style={{ fontSize: width > 700 ? 13 : 11, lineHeight: width > 700 ? "17px" : "13px", backgroundColor: "#c33c3c", color: "#fff", marginBottom: 15 }} onClick={() => authContext.changeModalInfo({ nameModal: "TCharytatywny", name: "Turniej charytatywny", value: "" })} >
                        <Icon name='heart' size='big' style={{ marginBottom: 7 }} />
                        Organizujesz turniej charytatywny?

                      </Button>
                      : ""}
                    {width < 1000 ? "" :
                      <Label style={{ marginBottom: 10 }} color='red' ribbon>
                        <Icon name='image' /> Plakat/zdjęcie
                      </Label>
                    }

                    <Icon style={{ position: "absolute", zIndex: 100, right: 2 }} className='cursorPointer' color="orange" name='edit' onClick={() => getValueDataInput(0, "Plakat", tournamentJson.img_file, "input", "img_file", tournamentId)} />
                    <div className={"plakatTurniej"} onClick={() => openModal(tournamentJson ? tournamentJson.img_file : noimage)} >
                      <MyImageLazy alt="plakat" className={"plakatTurniej"} src={tournamentJson && tournamentJson.img_file ? tournamentJson.img_file : noimage} />
                    </div>
                    {tournamentJson && tournamentJson.tournament_support === 0 && checkDateStartBeforeNow(tournamentJson.stop_date) && tournamentJson.charity_type !== 1 && !isDemo ?
                      <Segment color='blue'>
                        <Label style={{ marginBottom: 10 }} color='blue' ribbon>
                          <Icon name='handshake' /> Wsparcie techniczne
                        </Label>
                        <p style={{ fontSize: 13 }}>
                          <b>Drogi użytkowniku</b>,<br />
                          Nasz zespół ekspertów jest gotów pomóc online w rozwiązaniu wszelkich problemów oraz służyć pomocą podczas trwania rozgrywek,
                          abyś mógł cieszyć się grą bez żadnych przeszkód czy wątpliwości.<br /> Skorzystaj z naszego wsparcia, aby mieć pewność,
                          że każda chwila spędzona podczas rozgrywek będzie pełna ekscytującej rywalizacji.<br />
                          <b>Twój komfort to nasz priorytet</b> - z nami każdy aspekt rozgrywek stanie się jeszcze bardziej ekscytujący!</p>
                        <Button size='mini' color='blue' fluid onClick={() => authContext.changeModalInfo({ nameModal: "SupportTournament", name: "Wsparcie techniczne", value: { startTournament: tournamentJson ? tournamentJson.start_date : "2000-01-01", tournament_id: tournamentId } })}>Chce skorzystać</Button>

                      </Segment>
                      : tournamentJson && tournamentJson.tournament_support === 1 && checkDateStartBeforeNow(tournamentJson.stop_date) && tournamentJson.charity_type !== 1 ?
                        <Segment color='green'>
                          <Label style={{ marginBottom: 10 }} color='green' ribbon>
                            <Icon name='handshake' /> Wsparcie techniczne - AKTYWNE
                          </Label>
                          <p style={{ fontSize: 13 }}>
                            <b>Drogi użytkowniku</b>,<br />
                            Serdecznie dziękujemy za wykupienie naszego wsparcia. Jesteśmy gotowi służyć pomocą w każdej kwestii.
                            W razie jakichkolwiek pytań lub problemów prosimy o kontakt pod numerem telefonu specjalisty: <b><Icon name="mobile" />607750415</b>.<br />
                            <b>Dziękujemy za zaufanie</b>
                          </p>
                        </Segment>
                        : ""
                    }

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>


            {tournamentJson && tournamentJson.video_data ?
              <Container style={{ marginBottom: 10 }}>
                <ShowVideoPlayer viewSmall={false} arrDataVideo={tournamentJson.video_data} />
              </Container>
              : ""}

            {tournamentJson && tournamentJson.external_system_data && tournamentJson.external_system_data.external_system_url ?
              <Message info size='small'>
                <Grid className='mp0'>
                  <Grid.Row className='mp0'>
                    <Grid.Column width={2} >
                      <Image alt="cali6" src={cali6} size='small' />
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <p>
                        <b>Drogi użytkowniku</b>,<br />
                        Z radością informujemy, że turniej został dodatkowo utworzony na portalu 6cali.pl.<br /><Icon name="linkify" /><a href={tournamentJson.external_system_data.external_system_url} target="_blank" rel="noopener noreferrer">{tournamentJson.external_system_data.external_system_url}</a>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Message>
              : ""}
            {tournamentJson && (tournamentJson.points_calc_system_id === 2 || tournamentJson.points_calc_system_id === 17) && (tournamentJson.send_to_external_service === false || tournamentJson.send_to_external_service === 0 || (tournamentJson.send_to_external_service === 1 && tournamentJson.external_system_data && tournamentJson.external_system_data.external_system_url === null)) ?
              <Message info size='small'>
                <Grid className='mp0'>
                  <Grid.Row className='mp0'>
                    <Grid.Column width={2} >
                      <Image alt="cali6" src={cali6} size='small' />
                    </Grid.Column>
                    <Grid.Column width={14}>
                      <p className='cursorPointer' onClick={() => getValueDataInput(0, "Automatyczne publikacje turnieju", tournamentJson ? tournamentJson.send_to_external_service : "?", "externalSerwer", "send_to_external_service", tournamentId)}>
                        <b>Drogi użytkowniku</b>,<br />
                        <Icon color="orange" name='edit' />
                        Możesz włączyć automatycznie publikacje tego turnieju na portablu 6cali.pl<br />
                        <span style={{ fontSize: 12 }}>*Operacja nieodwracalna dla tego turnieju</span>
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Message>
              : ""}
            {tournamentJson && tournamentJson.tournament_free === 0 && tournamentJson.google_ads === 1 && Number(process.env.REACT_APP_IN_GOOGLEROFF) === 0 ?
              <Message>
                <Message.Header><Icon name="adversal" />Reklama Google</Message.Header>

                <AdSense.Google
                  client='ca-pub-7406426760252636'
                  slot='9334367606'
                  style={{ display: 'block', textAlign: "center" }}
                  layout='in-article'
                  format='fluid'
                />

              </Message>
              : ""}

            <Grid stackable  >
              <Grid.Row stretched>
                <Grid.Column >
                  <Segment loading={loading} >
                    <Label style={{ marginBottom: 10 }} color='red' ribbon>
                      <Icon name='sticky note' /> Opis rozgrywek
                    </Label><br />
                    <GetEditorTG rte={rte} setrte={setrte} />
                    <Button.Group fluid size='tiny' style={{ marginTop: 5 }}>
                      <Button color='grey' onClick={() => getValueDataInput(0, "Opis", rte, "RichTextEditor", "tournament_description", tournamentId)} >ZAPISZ ZMIANY OPISU</Button>
                      <Button.Or text='lub' />
                      <Button onClick={() => {
                        setrte("")
                        getValueDataInput(0, "Opis", "", "RichTextEditor", "tournament_description", tournamentId)
                      }
                      }>SKASUJ ZAWARTOŚĆ OPISU</Button>
                    </Button.Group>
                  </Segment>

                </Grid.Column>

              </Grid.Row>
            </Grid>
            <Divider />
          </>
            : ''}



        </>
      }
      <Header as='h5'  >
        <Icon name='arrow alternate circle right' />
        <Header.Content>Kategorie {tournamentJson && tournamentJson.tournament_free === 0 && tournamentJson.subtournaments && tournamentJson.subtournaments.length >= Number(process.env.REACT_APP_IN_LIMITBROWNCAT) ? <><Icon className='cursorPointer' color="orange" name='add' onClick={() => addToast("ZABLOKOWANE - max " + Number(process.env.REACT_APP_IN_LIMITBROWNCAT) + " kategorie w pakiecie brązowym", { appearance: 'warning' })} />{lockedCheck()}</> : <Icon className='cursorPointer' color="orange" name='add' onClick={() => getValueDataInput(0, "Dodaj nową kategorię", "", "InputKat", "subtournament_name", tournamentId)} />}</Header.Content>
      </Header>

      <Tab menu={{ secondary: true, inverted: true, pointing: true, color: "grey", className: "wrapped" }} panes={panes} style={{ marginBottom: 20 }} />

      <ImportMembers openMembers={openMembers} setopenMembers={setopenMembers} />
      <Modal
        open={openChangeUser}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='retweet' color="orange" />
                  <Header.Content>Zmiana uczestnika</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content image>

          <Modal.Description>
            Nowy uczestnik: <b>{formChangeUser.memberInfoCurrent ? formChangeUser.memberInfoCurrent.name : ""}</b>  <br />
            Zamiana <b>za</b> uczestnika: <b><Dropdown scrolling
              placeholder='Wybierz uczestnika'
              options={ChangeUsertab}
              value={formChangeUser.memberInfoOld}
              onChange={(e, d) => setformChangeUser({ ...formChangeUser, memberInfoOld: d.value })}
            /></b> (Uczestnik zostanie skasowany z grupy/drabinki)<br />

            <br />
            {ChangeUsertab && ChangeUsertab.length > 0 ? "" : <Message>BRAK UCZESTNIKÓW DO ZAMIANY...</Message>}
            <Button fluid color='orange' size='tiny' disabled={formChangeUser.memberInfoCurrent && formChangeUser.memberInfoOld && formChangeUser.memberInfoCurrent.name !== formChangeUser.memberInfoOld.member_name ? false : true} onClick={() => replaceMemberInTournament()}>PODMIEŃ UCZESTNIKA</Button>
            <Message size='tiny'>Jeżeli chcesz podmienić uczestnika, który rozegrał już jakieś mecze, należy je wcześniej skasować.</Message>
          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setopenChangeUser(false)}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>

      <Modal
        open={openChangeUserData.open}
      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h2' textAlign='center'>
                  <Icon name='edit' />
                  <Header.Content>Edycja dane</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content image>

          <Modal.Description>
            <MyTournamentEditUsers editType={openChangeUserData.edit} data={openChangeUserData.data} subTurnamentType={openChangeUserData.subTurnamentType} subTournamentId={openChangeUserData.subTournamentId} setopenChangeUserData={setopenChangeUserData} />
          </Modal.Description>

        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setopenChangeUserData({ open: false, data: null })}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
      <MembersInTeams openModalMembersTeams={openModalMembersTeams} setopenModalMembersTeams={setopenModalMembersTeams} />
      <Modal
        onClose={() => setopenIframe(false)}
        onOpen={() => setopenIframe(true)}
        open={openIframe}
        size={'large'}
        dimmer='blurring'

      >
        <Modal.Header>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={12} verticalAlign="middle">
                <Header as='h4' textAlign='center'>
                  <Icon name='add' />
                  <Header.Content>Dodawanie rozgrywek do swojej strony www</Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4} textAlign="right" only='large screen'>
                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator} />
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Modal.Header>
        <Modal.Content>

          <Modal.Description>
            <Segment inverted>
              <Label color='blue' ribbon>
                Link do rozgrywek
              </Label>

              {genIFrameData("https://turniej-generator.pl/tg/" + numberToCharTG(tournamentJson.tournament_id))}

            </Segment>

            {tournamentJson && tournamentJson.subtournaments ? tournamentJson.subtournaments.map((line, i) => (
              line.parent_subtournament_id !== null ? "" :
                <Segment key={i} inverted>
                  <Label color='blue' ribbon>
                    Link (np. sama drabinka turniejowa) dla uczestników do kategorii: {line.name}
                  </Label>

                  {genIFrameData("https://turniej-generator.pl/tsv/" + tournamentJson.tournament_id + "/" + line.subtournament_id)}
                  <Message warning size='tiny'>
                    <Message.Header>Ważne!</Message.Header>
                    <p>Po dodaniu linku do danej kategorii, nie trzeba nic odświeżać, wyniki same się odświeżaja co 60 sekund.</p>
                  </Message>
                </Segment>
            )) : ""}
            <br />
            Masz problem z wdrożeniem tego rozwiazania u siebie na stronie WWW?, zapraszamy do kontaktu.<br />
            PONIŻEJ PRZYKŁAD NA PODSTAWIE WIDOKU TURNIEJU width=100% oraz height=500
            <object type="text/html" data={"https://turniej-generator.pl/tg/" + numberToCharTG(tournamentJson.tournament_id) + "/iftg/light"} title="turniej-generator.pl" style={{ height: 500, width: "100%" }} />
            <br /><br />

            {tournamentJson && tournamentJson.subtournaments && tournamentJson.subtournaments.length > 0 ?
              <>
                PONIŻEJ PRZYKŁAD NA PODSTAWIE WIDOKU KONKRETNEJ KATEGORII width=100% oraz height=500
                <object type="text/html" data={"https://turniej-generator.pl/tsv/" + tournamentJson.tournament_id + "/" + tournamentJson.subtournaments[0].subtournament_id + "/iftg/light"} title="turniej-generator.pl" style={{ height: 500, width: "100%" }} />

              </>

              : ""}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => setopenIframe(false)}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
      {dataMailMembers.open ?
        <AddEmailForMember OpenModalAEFM={dataMailMembers.open} OpenModalAEFMDATA={dataMailMembers.data} setdataMailMembers={setdataMailMembers} dateForce={true} />
        : ""}
      <AddMembersToTournament setopenModalAddMembersToTournament={setopenModalAddMembersToTournament} openModalAddMembersToTournament={openModalAddMembersToTournament} />
    </div>
  )

  function genIFrameData(link) {

    return <div style={{ marginTop: 10, fontSize: 13 }}><Message  >

      <CodeBlock
        text={'<object type="text/html" data="' + link + '/iftg/light" style="height:500px; width:100%"/>'}
        language='html'
        showLineNumbers={false}

      />
      {/* 
      Metoda 2:
      <CodeBlock
        text={'<iframe src="' + link + '/iftg/light" style="height: 500px; width:100%; border: 0;"></iframe>'}
        language='html'
        showLineNumbers={false}

      />
*/}
    </Message>
      <br />*Parametry width oraz height można dowolnie zmieniać i ustawiać im % lub px.
      <br />*Parametry light można zamienić na [dark] wówczas zostanie zastosowany ciemny motyw lub zostawić na [light] .
      <br />*Parametry iftg można zamienić na [iftgv2] lub [iftg] .
    </div>
  }
}

export default MyTournament;