import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Container, Button, Form, Message, Select, Grid, Table, Segment, Label, Icon, Input, Divider, Header, Checkbox } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import StepCreateTournamentGroups from '../Dashboard/MyAccount/StepCreateTournamentGroups';
import BracketGen from './BracketGen';
import useAuth from '../../../hooks/useAuth';
import bergerTable from 'berger-table-generator';
import { blockEditScore, getInfroMatchPlace, getStosunekPTK, getlinkplayerprofile } from '../../../myfunctions';
import BracketGenHarm from './BracketGenHarm';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import GetScoreDetails from './GetScoreDetails';
import ShowScoreDetails from './ShowScoreDetails';
import CurrentDuelsPlayingGroups from './CurrentDuelsPlayingGroups';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';

function BracketGroups(propsRoot) {
    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);
    const [openModalScore, setopenModalScore] = useState(null)// eslint-disable-line no-unused-vars
    const [openUsersAdd, setOpenUsersAdd] = useState(false)
    const [openModalHarm, setopenModalHarm] = useState(false)
    const [tournamentGroups, settournamentGroups] = useState()
    const [formUsersAdd, setfromUsersAdd] = useState({ subtournament_group_member_id: 0, member_id: -100 })
    const [messageStatus, setmessageStatus] = useState({ state: 0, text: "" })
    const [tournamentId, settournamentId] = useState(0)// eslint-disable-line no-unused-vars
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [userList, setuserList] = useState([])
    const [chooseTypeCup, setchooseTypeCup] = useState("CUP_MAIN")
    const [main_tournament_members, setmain_tournament_members] = useState([])
    const [consolation_tournament_members, setconsolation_tournament_members] = useState([])
    const [TypeCup, setTypeCup] = useState([])
    const [isCupGened, setisCupGened] = useState(false)
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [checkboxMD, setcheckboxMD] = useState(true)
    const { t } = useTranslation();
    const [loading, setloading] = useState(false)

    const [loadingPlaceTab, setloadingPlaceTab] = useState(0)

    const [presaveplace, setpresaveplace] = useState({ id: 0, value: null })

    function changechooseTypeCup(val) {
        setchooseTypeCup(val)
        setconsolation_tournament_members([])
        setmain_tournament_members([])

    }

    function checkConsolation() {
        if (chooseTypeCup && chooseTypeCup.match("CONSOLATION")) {
            return true
        } else {
            return false
        }

    }

    function arrayAddM(subtournament_group_member_id) {
        let check = 0
        main_tournament_members.forEach((row, i) => {
            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id && row.subtournament_group_member_id !== null) {
                check = 1
            }
        })
        consolation_tournament_members.forEach((row, i) => {
            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id && row.subtournament_group_member_id !== null) {
                check = 1
            }
        })
        if (check === 0) {
            // dodajemy sortowanie dla pucharowego do ułozenia po grupach
            setmain_tournament_members([...main_tournament_members, subtournament_group_member_id].sort((a, b) => {
                return a.order_nr - b.order_nr || a.group_nr - b.group_nr;
            }));
        } else {
            addToast("Ten uczestnik jest już dodany do listy", { appearance: 'warning' });
        }

    }

    function checkExistTable(subtournament_group_member_id) {
        let check = 0
        main_tournament_members.forEach((row, i) => {
            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id) {
                check = 1
            }
        })
        consolation_tournament_members.forEach((row, i) => {

            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id) {
                check = 2
            }
        })
        return check
    }

    function arrayAddC(subtournament_group_member_id) {
        let check = 0
        consolation_tournament_members.forEach((row, i) => {

            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id && row.subtournament_group_member_id !== null) {
                check = 1
            }
        })
        main_tournament_members.forEach((row, i) => {
            if (row.subtournament_group_member_id === subtournament_group_member_id.subtournament_group_member_id && row.subtournament_group_member_id !== null) {
                check = 1
            }
        })
        if (check === 0) {
            // dodajemy sortowanie dla pucharowego do ułozenia po grupach
            setconsolation_tournament_members([...consolation_tournament_members, subtournament_group_member_id].sort((a, b) => {
                return a.order_nr - b.order_nr || a.group_nr - b.group_nr;
            }));
        } else {
            addToast("Ten uczestnik jest już dodany do listy", { appearance: 'warning' });
        }

    }

    function arrayRemoveM(index) {
        const rows = [...main_tournament_members];
        rows.splice(index, 1);
        setmain_tournament_members(rows);
    }

    function arrayRemoveMName(playername, subtournament_group_member_id) {
        const rows = [...main_tournament_members];
        if (rows && rows.length > 0) {
            rows.forEach((row, i) => {
                if (row.name === playername || row.subtournament_group_member_id === subtournament_group_member_id) {
                    rows.splice(i, 1);
                }
            })
        }
        setmain_tournament_members(rows);
    }

    function arrayRemoveC(index) {
        const rows = [...consolation_tournament_members];
        rows.splice(index, 1);
        setconsolation_tournament_members(rows);
    }

    function arrayRemoveCName(playername, subtournament_group_member_id) {
        const rows = [...consolation_tournament_members];
        if (rows && rows.length > 0) {
            rows.forEach((row, i) => {
                if (row.name === playername || row.subtournament_group_member_id === subtournament_group_member_id) {
                    rows.splice(i, 1);
                }
            })
        }
        setconsolation_tournament_members(rows);
    }


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


        if (propsRoot.remoteGroups && propsRoot.struct_confirmed === 0) {
            settournamentGroups(propsRoot.remoteGroups)
            settournamentId(propsRoot.tournamentId)
            getListUsers(propsRoot.tournamentId)
        }
        else if (propsRoot.remoteGroups && propsRoot.struct_confirmed === 1) {

            settournamentGroups(propsRoot.remoteGroups)
            settournamentId(propsRoot.tournamentId)
        } else {
            console.log("BRAK JSONA")
        }

        if (propsRoot.subtournaments && propsRoot.subtournaments.length > 0) {
            let tmp1 = 0
            propsRoot.subtournaments.forEach((line, i) => {
                if (line.parent_subtournament_id === propsRoot.tournamentId) {
                    tmp1++
                }
            })
            if (tmp1 > 0) {
                setisCupGened(true)
            } else {
                setisCupGened(false)
            }
        } else {
            setisCupGened(false)
        }


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.struct_confirmed, propsRoot.remoteGroups, propsRoot.subtournaments, propsRoot.tournamentId, propsRoot.linkTournament])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps


        getGroupsBracketTypes()


        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [])// eslint-disable-line react-hooks/exhaustive-deps



    function getListUsers(subtournament_id) {
        ConnectWS("/generator/getUnassignedSubtournamentMembers", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    dictemat.push({ key: i, text: data.member_name, value: data.member_id })
                    //dictemat.push({ key: i, text: data.Login, value: data.Login })
                })

                setuserList(dictemat);
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }

    function getGroupsBracketTypes() {
        ConnectWS("/generator/getGroupsBracketTypes", authContext.token, {}, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    if (data.id === "CUP_MAIN" || data.id === "CUP_MAIN_CONSOLATION") {
                        dictemat.push({ key: i, text: data.name, value: data.id })
                        dictemat.push({ key: i + 100, text: data.name + " (PUSTA DRABINKA)", value: data.id + "_EMPTY_BRACKET" })
                    }
                    //dictemat.push({ key: i, text: data.name + " - " + data.desc, value: data.id })
                })

                setTypeCup(dictemat);
            } else {

            }



        });
    }


    function createSubtournamentGroupsBrackets() {
        let subtournament_id = propsRoot.tournamentId
        if (main_tournament_members.length < 4) {
            addToast("Proszę przypisać uczestników do turnieju głównego min 4", { appearance: 'warning' });
            return
        }

        if (checkConsolation() && consolation_tournament_members.length < 4) {
            addToast("Proszę przypisać uczestników do turnieju pocieszenia min 4", { appearance: 'warning' });
            return
        }
        let empty_bracket = chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ? true : false

        ConnectWS("/generator/createSubtournamentGroupsBrackets", authContext.token, { bracketType: chooseTypeCup.replaceAll("_EMPTY_BRACKET", ""), empty_bracket: empty_bracket, subtournament_id: subtournament_id, main_tournament_members: main_tournament_members, consolation_tournament_members: consolation_tournament_members }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                authContext.changeRefreshView(authContext.refreshView + 1)
            }



        });
    }


    function exitModal() {
        setmessageStatus({ state: 0, text: "" })
        setfromUsersAdd({ subtournament_group_member_id: 0, member_id: -100 })
        setopenModalScore(null)
        setOpenUsersAdd(false)
    }


    function OpenModalUser(subtournament_group_member_id, member_id = -100) {
        setfromUsersAdd({ subtournament_group_member_id: subtournament_group_member_id, member_id: member_id })
        setOpenUsersAdd(true)

    }

    function removeMemberFromGroup(subtournament_group_member_id) {

        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz skasować uczestnika ',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => removeMemberFromGroupA(subtournament_group_member_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function removeMemberFromGroupA(subtournament_group_member_id) {

            ConnectWS("/generator/removeMemberFromGroup ", authContext.token, { subtournament_group_member_id: subtournament_group_member_id }, function (response, logout) {

                if (logout === 1) {

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    addToast(response, { appearance: 'success' });
                }



            });
        }
    }



    function modifiDataJsonUserACC() {

        if (formUsersAdd.member_id < -90 || formUsersAdd.member_id === null) {
            setmessageStatus({ state: 1, text: "Wybierz uczestnika" })
            return
        }


        let member_id = formUsersAdd.member_id === -2 ? null : formUsersAdd.member_id === -1 ? 0 : formUsersAdd.member_id
        let subtournament_group_member_id = formUsersAdd.subtournament_group_member_id
        setloading(true)
        ConnectWS("/generator/addMemberToGroup", authContext.token, { subtournament_group_member_id: subtournament_group_member_id, member_id: member_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                //let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)



        });
    }



    function checkScoreGroupsMM(line, m1, m2, arrMatch, a, b) {
        return line.matches.map(line4 => {
            if (line4.subtournament_group_member1_id === m1 && line4.subtournament_group_member2_id === m2) {

                if (line4.member1_score !== null && line4.member2_score !== null && line4.member1_score >= 0 && line4.member2_score >= 0) {
                    return <><span style={{ fontWeight: "bold", color: line4.member1_score > line4.member2_score ? "#03ab21" : line4.member1_score === line4.member2_score ? "#d9a60b" : "#e82c2c" }}>{line4.member1_score}:{line4.member2_score}</span> {propsRoot.onlyView === 1 ? "" : <Icon className='cursorPointer hiddenPrint' title={"Edytuj wynik: [" + line4.member1_name + "]-[" + line4.member2_name + "]"} size='small' name="edit" color='grey' onClick={() => setopenModalScore({ subtournament_group_member1_id: line4.subtournament_group_member1_id, subtournament_group_member2_id: line4.subtournament_group_member2_id, subtournament_group_match_id: line4.subtournament_group_match_id, member1_score: line4.member1_score, member2_score: line4.member2_score, member1_name: line4.member1_name, member2_name: line4.member2_name, match_details: line4.match_details, match_place: line4.match_place })} />}<ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: false, checkboxMD: checkboxMD }} /></>;
                } else {
                    return <>{getInfroMatchPlace(line4.match_place)} {propsRoot.onlyView === 1 ? "" : <> <Icon className='cursorPointer hiddenPrint' title={"Dodaj wynik: [" + line4.member1_name + "]-[" + line4.member2_name + "]"} name="add" onClick={() => setopenModalScore({ subtournament_group_member1_id: line4.subtournament_group_member1_id, subtournament_group_member2_id: line4.subtournament_group_member2_id, subtournament_group_match_id: line4.subtournament_group_match_id, member1_score: line4.member1_score, member2_score: line4.member2_score, member1_name: line4.member1_name, member2_name: line4.member2_name, match_details: line4.match_details, match_place: line4.match_place })} /> </>} <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: false, checkboxMD: checkboxMD }} /></>;
                }

            } else if (line4.subtournament_group_member1_id === m2 && line4.subtournament_group_member2_id === m1) {
                if (line4.member1_score !== null && line4.member2_score !== null && line4.member1_score >= 0 && line4.member2_score >= 0) {
                    arrMatch.push(reverseScore((b + 1), (a + 1)))
                    return <><span style={{ fontWeight: "bold", color: line4.member1_score < line4.member2_score ? "#03ab21" : line4.member1_score === line4.member2_score ? "#d9a60b" : "#e82c2c" }}>{line4.member2_score}:{line4.member1_score} </span> <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: true, checkboxMD: checkboxMD }} /></>
                } else {
                    return <>{getInfroMatchPlace(line4.match_place)} <ShowScoreDetails refreshView={authContext.refreshView} matche_info={{ member1_img_file: line4.member1_img_file, member2_img_file: line4.member2_img_file, member1_name: line4.member1_name, member2_name: line4.member2_name, member1_score: line4.member1_score, member2_score: line4.member2_score }} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: line4.subtournament_group_match_id, match_details: line4.match_details, match_member1_id: line4.subtournament_group_member1_id, match_member2_id: line4.subtournament_group_member2_id, reverse: true, checkboxMD: checkboxMD }} /></>;
                }
            }
            return ""
        })

    }

    function reverseScore(a, b) {
        if (Number(a) > Number(b)) {
            return (b + "-" + a)
        } else {
            return (a + "-" + b)
        }

    }

    function generatorMatchCount(countMatches, arrMatchTmp) {
        try {
            let arr = bergerTable(countMatches)
            if (arr && arr.length > 0) {
                return arr.map((row, i) => {
                    return row.map((row1, i) => {
                        if (arrMatchTmp.includes(reverseScore((row1.teamA + 1), (row1.teamB + 1)))) {
                            return <span style={{ fontSize: 12, marginRight: 5 }}><s><i>{reverseScore((row1.teamA + 1), (row1.teamB + 1))}</i></s>,</span>
                        } else {
                            return <span style={{ marginRight: 5, fontSize: 14, color: "#000" }}>{reverseScore((row1.teamA + 1), (row1.teamB + 1))},</span>
                        }

                    })
                })
            } else {
                return ""
            }
        } catch (err) {
            console.log(err)
        }

    }

    function showScoreDetailsSmall(line) {
        try {

            if (line.matches && line.matches.length > 0) {
                for (const row of line.matches) {
                    if (row.match_details != null && JSON.parse(row.match_details).score) {
                        return true
                    }
                }
            }
            return false
        } catch (e) {
            return false
        }

    }

    function getScoreDetailsSmall(line, line4) {
        try {
            let W = 0;
            let L = 0;
            if (line.matches && line.matches.length > 0) {
                for (const row of line.matches) {
                    if (row.match_details != null && JSON.parse(row.match_details).score && (line4.subtournament_group_member_id === row.subtournament_group_member1_id || line4.subtournament_group_member_id === row.subtournament_group_member2_id)) {
                        for (const rowS of JSON.parse(row.match_details).score) {
                            W = W + rowS[line4.subtournament_group_member_id]
                            if (row.subtournament_group_member1_id === line4.subtournament_group_member_id) {
                                L = L + rowS[row.subtournament_group_member2_id]
                            } else {
                                L = L + rowS[row.subtournament_group_member1_id]
                            }
                        }

                    }
                }
            }
            return <>{W + ":" + L}<br /><span style={{ fontSize: 11 }}>({getStosunekPTK(W, L)})</span></>
        } catch (e) {
            return ""
        }

    }



    function changePlaceTab(d, row) {
        console.log(row)
        setloadingPlaceTab(row.subtournament_group_member_id)
        ConnectWS("/generator/setMemberGroupCustomOrderNr", authContext.token, { subtournament_group_member_id: row.subtournament_group_member_id, manual_order_nr: d === null || d === "" ? null : Number(d) }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                setpresaveplace({ id: 0, value: null })
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)


            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
            setloadingPlaceTab(0)


        });
    }

    function generatorTable(line) {
        let arrMatch = []
        let showScoreD = showScoreDetailsSmall(line)
        return <><Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell singleLine style={{ width: 40 }} textAlign='center'>LP</Table.HeaderCell>
                    <Table.HeaderCell style={{ fontSize: 12 }} textAlign='center' width={5} >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                    {line && line.members ? line.members.map((line4, i) =>


                        <Table.HeaderCell textAlign='center' key={i}>
                            {i + 1}
                        </Table.HeaderCell>

                    )
                        : ""}
                    <Table.HeaderCell style={{ width: 80 }} textAlign='center'>{t("WidokTurnieju.tabela_ptk1")}</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: 80 }} textAlign='center'>{t("WidokTurnieju.tabela_bilans")}<br /><span style={{ fontSize: 11 }}>({t("WidokTurnieju.tabela_stosunek")})</span></Table.HeaderCell>
                    {showScoreD ? <Table.HeaderCell style={{ width: 80 }} textAlign='center' >{t("WidokTurnieju.tabela_bilans")}<br /><span style={{ fontSize: 9, lineHeight: "8px" }}>{t("WidokTurnieju.tabela_bilans_szczegoly")}</span> <span style={{ fontSize: 9 }}>({t("WidokTurnieju.tabela_stosunek")})</span></Table.HeaderCell> : ""}

                    <Table.HeaderCell style={{ width: 40 }} textAlign='center'>{propsRoot.onlyView === 1 ? <span style={{ fontSize: 9, writingMode: "vertical-rl", textOrientation: "mixed" }}> {t("WidokTurnieju.miejsce_w_tab")} </span> : t("WidokTurnieju.miejsce_w_tab")}</Table.HeaderCell>
                    {propsRoot.onlyView === 1 || isCupGened === true ? "" :
                        <>
                            <Table.HeaderCell style={{ width: 40 }} textAlign='center' className='hiddenPrint'>TURNIEJ GŁÓWNY</Table.HeaderCell>
                            {checkConsolation() ? <Table.HeaderCell style={{ width: 40 }} textAlign='center' className='hiddenPrint'>TURNIEJ POCIESZENIA</Table.HeaderCell> : ''}
                        </>}


                </Table.Row>
            </Table.Header>
            <Table.Body>
                {line && line.members ?

                    line.members.map((line4, i) =>

                        <Table.Row key={i}>
                            <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                            <Table.Cell textAlign='left' style={{ fontSize: 12 }} >
                                <figure className='imgPlayer'><ShowImgAvatar urlpic={line4.member_img_file} mtype={"0"} msize={"mini"} /><figcaption>{getlinkplayerprofile(null, line4.member_name, 0, 14)}</figcaption></figure>
                            </Table.Cell>
                            {line && line.members ? line.members.map((line5, j) =>
                                <>

                                    <Table.Cell style={{ position: "relative" }} disabled={i === j ? true : false} textAlign='center' key={j} singleLine>

                                        {i === j ? <Icon name="x" size='large' /> : checkScoreGroupsMM(line, line4.subtournament_group_member_id, line5.subtournament_group_member_id, arrMatch, i, j)}
                                    </Table.Cell>
                                </>
                            )
                                : ""}

                            {line && line.result_table ? line.result_table.map((line6, j) =>
                                line6.subtournament_group_member_id === line4.subtournament_group_member_id ?
                                    <>
                                        <Table.Cell textAlign='center' key={j}>
                                            <b>{line6.subtournament_point}</b>
                                        </Table.Cell>
                                        <Table.Cell textAlign='center' singleLine>
                                            {line6.total_points_winners}:{line6.total_points_losers} <span style={{ fontSize: 11 }}><br />({getStosunekPTK(line6.total_points_winners, line6.total_points_losers)})</span>
                                        </Table.Cell>

                                        {showScoreD ? <Table.Cell textAlign='center'>{getScoreDetailsSmall(line, line4)}</Table.Cell> : ""}
                                        <Table.Cell textAlign='center'  >
                                            {propsRoot.onlyView === 1 ? <b>{line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr}</b> : <div >
                                                {isCupGened === true || loadingPlaceTab || checkExistTable({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name }) > 0 ?
                                                    <b>{line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr}</b> :
                                                    <> <Input error={line6.manual_order_nr ? true : false} type="number" size='small' loading={line6.subtournament_group_member_id === loadingPlaceTab ? true : false} disabled={false} fluid value={presaveplace.id === line6.id ? presaveplace.value : line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr ? line6.order_nr : ""} onChange={(e, d) => setpresaveplace({ id: line6.id, value: d.value })} />
                                                        {presaveplace.id === line6.id ? <Icon name="save" color='orange' style={{ marginTop: 5 }} className='cursorPointer' title="Zapisz ustawienia" onClick={() => changePlaceTab(presaveplace.value, line6)} /> : ""}
                                                        {line6.manual_order_nr ? <Icon name="trash" style={{ marginTop: 5 }} className='cursorPointer' title="Kasuj ustawione ręczne miejsce" onClick={() => changePlaceTab(null, line6)} /> : ""}
                                                    </>
                                                }
                                            </div>}
                                        </Table.Cell>
                                        {propsRoot.onlyView === 1 || isCupGened === true ? "" :
                                            <>
                                                <Table.Cell textAlign='center' className='hiddenPrint'>
                                                    {checkExistTable({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name }) === 1 ? <Icon name="trash" className='cursorPointer' onClick={() => arrayRemoveMName(line6.member_name, line6.subtournament_group_member_id)} /> :
                                                        checkExistTable({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name }) === 2 ? "" :
                                                            chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ?
                                                                <Icon name="add" color="purple" className='cursorPointer hiddenPrint' onClick={() => arrayAddM({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.manual_order_nr ? "G:" + line.group_nr + " M:" + line6.manual_order_nr : "G:" + line.group_nr + " M:" + line6.order_nr, tournament_member_id: line6.tournament_member_id, order_nr: line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr, group_nr: line.group_nr })} />
                                                                :
                                                                <Icon name="add" color="green" className='cursorPointer hiddenPrint' onClick={() => arrayAddM({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name, tournament_member_id: line6.tournament_member_id, order_nr: line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr, group_nr: line.group_nr })} />
                                                    }
                                                </Table.Cell>
                                                {checkConsolation() ?
                                                    <Table.Cell textAlign='center' className='hiddenPrint'>
                                                        {checkExistTable({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name }) === 2 ? <Icon name="trash" className='cursorPointer' onClick={() => arrayRemoveCName(line6.member_name, line6.subtournament_group_member_id)} /> :
                                                            checkExistTable({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name }) === 1 ? "" :
                                                                chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ?
                                                                    <Icon name="add" color="violet" className='cursorPointer hiddenPrint' onClick={() => arrayAddC({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.manual_order_nr ? "G:" + line.group_nr + " M:" + line6.manual_order_nr : "G:" + line.group_nr + " M:" + line6.order_nr, tournament_member_id: line6.tournament_member_id, order_nr: line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr, group_nr: line.group_nr })} />
                                                                    :
                                                                    <Icon name="add" color="blue" className='cursorPointer hiddenPrint' onClick={() => arrayAddC({ subtournament_group_member_id: line6.subtournament_group_member_id, name: line6.member_name, tournament_member_id: line6.tournament_member_id, order_nr: line6.manual_order_nr ? line6.manual_order_nr : line6.order_nr, group_nr: line.group_nr })} />
                                                        }
                                                    </Table.Cell>

                                                    : ""}
                                            </>}
                                    </>
                                    : ""
                            )
                                : ""}
                        </Table.Row>

                    )

                    : ""}
            </Table.Body>


        </Table>

            <div style={{ overflowX: "auto", width: width < 700 ? (width - 130) : "100%", fontSize: 10, marginTo: 5, color: "#b1b1b1", lineHeight: "16px" }} >
                {t("WidokTurnieju.tabela_bergera")}:<br /> {generatorMatchCount(line.members.length, arrMatch)}
            </div>



        </>
    }

    function saveScore() {

        setmessageStatus({ state: 0, text: "" })

        let nmin = 0
        let nmax = 200
        let removeScore = false;


        if (openModalScore.member1_score === null && openModalScore.member2_score === null) {
            // setmessageStatus({ state: 1, text: "Wpisz wynik meczu " })
            // return
            removeScore = true
        }

        if (openModalScore.member1_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla czerwonego" })
            return
        } else if (openModalScore.member1_score < nmin || openModalScore.member1_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (openModalScore.member2_score === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla niebieskiego" })
            return
        } else if (openModalScore.member2_score < nmin || openModalScore.member2_score > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }

        if (openModalScore.member1_score === "" || openModalScore.member2_score === "") {
            removeScore = true
        }



        let jsonObj = { ...openModalScore, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, removeScore: removeScore }
        setloading(true)
        ConnectWS("/generator/setMatchScoreMembersGroup", authContext.token, jsonObj, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })

            }
            setloading(false)


        });

    }


    function recreateCupTStruc(subtournament_id) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz usunąć strukturę turnieju pucharowego?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => recreateCupTStrucTA(subtournament_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function recreateCupTStrucTA(subtournament_id) {

            ConnectWS("/generator/unconfirmSubtournamentStruct", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {
                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }

            });
        }
    }



    function recreateCupT(subtournament_id) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: 'Czy chcesz usunąć turniej pucharowy?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => recreateCupTA(subtournament_id)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

        function recreateCupTA(subtournament_id) {

            ConnectWS("/generator/removeSubtournament", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {
                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);

                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }

            });
        }
    }

    const saveScorePre = async e => {
        e.preventDefault();
        saveScore()
    }

    function delScore() {
        setloading(true)
        ConnectWS("/generator/setMatchScoreMembersGroup", authContext.token, { ...openModalScore, removeScore: true }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setloading(false)


        });
    }

    //drukowanie system turniejowy
    const refs = useRef([]);




    return (
        <>

            {tournamentGroups && tournamentGroups.length > 0 && propsRoot.struct_confirmed === 0 ?

                <Grid stackable style={{ marginBottom: 0 }} >
                    <Grid.Row stretched columns={tournamentGroups.length > 2 ? 2 : tournamentGroups.length}>
                        {tournamentGroups.map((line, i) => (
                            <Grid.Column key={i}>
                                <Segment>
                                    <Label color='blue' ribbon>
                                        {t("WidokTurnieju.grupa")} {i + 1}
                                    </Label>
                                    <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                        <Table size='small' inverted={propsRoot.isDarkTheme} celled striped compact unstackable >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>

                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>

                                                {line && line.members ?
                                                    line.members.map((line3, i) => <Table.Row key={i}>
                                                        <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            {line3.member_id === null ? <Icon name="add" className='cursorPointer hiddenPrint' title="Przypisz uczestnika" onClick={() => OpenModalUser(line3.subtournament_group_member_id, line3.member_id)} />
                                                                : <> {line3.member_name} <Icon onClick={() => removeMemberFromGroup(line3.subtournament_group_member_id)} className='deluserico cursorPointer' name="trash" /></>}

                                                        </Table.Cell>

                                                    </Table.Row>)
                                                    : ""}

                                            </Table.Body>


                                        </Table>
                                    </div>
                                </Segment>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </Grid>

                : tournamentGroups && tournamentGroups.length > 0 && propsRoot.struct_confirmed === 1 ?
                    <>

                        {propsRoot.onlyView === 5 || propsRoot.viewTV === 1 ? '' : <>
                            <Button size='mini' icon labelPosition='left' style={{ marginBottom: 10, marginTop: 20 }} className='hiddenPrint' color={propsRoot.onlyView === 1 ? '' : 'brown'} onClick={() => setopenModalHarm(true)}> <Icon name='file' />{propsRoot.onlyView === 1 ? t("WidokTurnieju.zobacz_harmonogram_gier") : "GENERUJ HARMONOGRAM GIER PDF/CSV"}</Button>
                            <BracketGenHarm onlyView={propsRoot.onlyView} linkTournament={propsRoot.linkTournament} countMatches={tournamentGroups} show={openModalHarm} setopenModalHarm={setopenModalHarm} />
                        </>}
                        {propsRoot.onlyView === 1 ? '' : <CurrentDuelsPlayingGroups subtournament_id={propsRoot.tournamentId} tournamentGroups={tournamentGroups} setopenModalScore={setopenModalScore} />}

                        {propsRoot.viewTV === 1 ? "" :
                            <div className='smallchbox' style={{ marginBottom: 10 }}>
                                <Checkbox className='hiddenPrint' toggle label={t("PokazWynikiWszczegolowe")} checked={checkboxMD} onChange={(e, d) => setcheckboxMD(d.checked)} />
                            </div>
                        }

                        <Grid stackable style={{ marginBottom: 0 }}>

                            <Grid.Row stretched columns={propsRoot.viewTV === 1 ? 1 : width < 1400 ? 1 : authContext.onlyOneColumn ? 1 : tournamentGroups && tournamentGroups.length > 1 && tournamentGroups[0].members && tournamentGroups[0].members.length > 6 ? 1 : 2}>
                                {tournamentGroups.map((line, i) => (

                                    <Grid.Column key={i} style={{ marginBottom: 10 }}>
                                        <div ref={(el) => (refs.current[i] = el)}>
                                            <style type="text/css" media="print">
                                                {" @page { size: landscape; } "}
                                            </style>
                                            <Segment className='CardBlack'>

                                                <Label color='blue' ribbon>
                                                    {t("WidokTurnieju.grupa")} {i + 1}
                                                </Label>
                                                {propsRoot.onlyView === 0 ? <ReactToPrint
                                                    trigger={() => <Icon className='cursorPointer tllink hiddenPrint' style={{ position: "absolute", right: 11, top: 10 }} title="DRUKUJ" bordered floated name="print" />}
                                                    content={() => refs.current[i]}
                                                /> : ""}



                                                <div style={{ overflowX: "auto", width: width < 700 ? "100%" : "100%" }} >
                                                    {generatorTable(line)}
                                                </div>

                                            </Segment>
                                        </div>
                                    </Grid.Column>
                                ))}
                            </Grid.Row>
                        </Grid>
                        {propsRoot.onlyView === 0 && isCupGened === false ?
                            <Segment className='hiddenPrint'>
                                <Label color='red' ribbon>
                                    Typ kontynuowanego turnieju
                                </Label>
                                <Grid columns={width < 1400 ? 1 : 2} stackable textAlign='center'>


                                    <Grid.Row verticalAlign='middle'>
                                        <Grid.Column>
                                            {chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ? <Message info size='small' style={{ marginTop: 10 }}>

                                                <p> <b>WAŻNE!</b> Wybierz w dowolnej kolejności <b>miejsa z danej grupy</b> np <i> (grajac pucharowy na 4 uczestników wybierz z grupy 1 miejsce 1,2 oraz z grupy 2 miejsce 1,2)</i> lista zostanie automatycznie posortowana po miejscu oraz grupie.


                                                </p>
                                            </Message> :
                                                <Message size='small' style={{ marginTop: 10 }}>

                                                    <p>
                                                        Po rozegraniu wszystkich meczy w grupach, przejdź do turnieju pucharowego.
                                                        Wybierz w dowolnej kolejności uczestników, którzy awansowali do kolejnego etapu, lista zostanie automatycznie posortowana po miejscu oraz grupie.


                                                    </p>
                                                </Message>
                                            }

                                            <Form size='tiny'  >


                                                <Form.Field
                                                    control={Select} selectOnBlur={false}
                                                    label='Typ turnieju'
                                                    options={TypeCup}
                                                    onChange={(e, d) => changechooseTypeCup(d.value)}
                                                    value={chooseTypeCup}
                                                    placeholder='Typ turnieju'
                                                />

                                                <Form.Button size='tiny' style={{ marginBottom: 5 }} color="google plus" onClick={() => createSubtournamentGroupsBrackets()} fluid>Wybieram</Form.Button>



                                            </Form>
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Header as="h4">Lista Uczestników</Header>

                                            <Grid columns={checkConsolation() ? 2 : 1}>
                                                <Grid.Row>

                                                    {main_tournament_members.length > 0 ?
                                                        <Grid.Column>


                                                            Turniej Głowny
                                                            <div style={{ overflowX: "auto", width: width < 700 ? (width - 200) : "100%" }} >
                                                                <Table size='small' color="green" celled striped compact unstackable >
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                                                                            <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>GRUPA</Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>MIEJSCE</Table.HeaderCell>
                                                                            <Table.HeaderCell textAlign='center' width={1}></Table.HeaderCell>

                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>

                                                                        {main_tournament_members.length > 0 ?
                                                                            main_tournament_members.map((row1, i) => <Table.Row key={i}>
                                                                                <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.name}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.group_nr}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.order_nr}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    <Icon name="trash" className='cursorPointer' onClick={() => arrayRemoveM(i)} />
                                                                                </Table.Cell>

                                                                            </Table.Row>)
                                                                            : ""}

                                                                    </Table.Body>


                                                                </Table>
                                                            </div>
                                                        </Grid.Column>
                                                        : <Grid.Column>
                                                            {chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ? <Message size='small' warning>
                                                                <Message.Header>WAŻNE!</Message.Header>
                                                                <p>Proszę przypisać uczestników do turnieju pucharowego (głownego)
                                                                    <br /> Poprzez wybranie uczestnika  <Icon name="add" color="purple" className='hiddenPrint' /> z tabeli grupowej proszę się sugerować numerem grupy oraz miejscem

                                                                    <br />
                                                                    Wygenerowanie pustej drabinki służy do zobrazowania dla uczestników jak wygląda rozkład na niej.
                                                                </p>
                                                            </Message> :
                                                                <Message size='small' warning>
                                                                    <Message.Header>WAŻNE!</Message.Header>
                                                                    <p>Proszę przypisać uczestników do turnieju pucharowego (głownego)
                                                                        <br /> Poprzez wybranie uczestnika  <Icon name="add" color="green" className='hiddenPrint' /> z tabeli grupowej
                                                                    </p>
                                                                </Message>
                                                            }
                                                        </Grid.Column>}

                                                    {consolation_tournament_members.length > 0 && checkConsolation() ?
                                                        <Grid.Column>

                                                            Turniej Pocieszenia
                                                            <div style={{ overflowX: "auto", width: width < 700 ? (width - 200) : "100%" }} >
                                                                <Table size='small' color="blue" celled striped compact unstackable >
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>LP</Table.HeaderCell>
                                                                            <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>GRUPA</Table.HeaderCell>
                                                                            <Table.HeaderCell singleLine width={1} textAlign='center'>MIEJSCE</Table.HeaderCell>

                                                                            <Table.HeaderCell textAlign='center'></Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>

                                                                        {consolation_tournament_members.length > 0 ?
                                                                            consolation_tournament_members.map((row1, i) => <Table.Row key={i}>
                                                                                <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.name}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.group_nr}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>
                                                                                    {row1.order_nr}
                                                                                </Table.Cell>
                                                                                <Table.Cell textAlign='center'>

                                                                                    <Icon name="trash" className='cursorPointer' onClick={() => arrayRemoveC(i)} />
                                                                                </Table.Cell>
                                                                            </Table.Row>)
                                                                            : ""}

                                                                    </Table.Body>


                                                                </Table>
                                                            </div>
                                                        </Grid.Column>
                                                        : checkConsolation() ? <Grid.Column>
                                                            {chooseTypeCup && chooseTypeCup.includes("EMPTY_BRACKET") ? <Message size='small' info>
                                                                <Message.Header>WAŻNE!</Message.Header>
                                                                <p>Proszę przypisać uczestników do turnieju pucharowego (pocieszenia)
                                                                    <br /> Poprzez wybranie uczestnika  <Icon name="add" color="violet" className='hiddenPrint' /> z tabeli grupowej proszę się sugerować numerem grupy oraz miejscem

                                                                    <br />
                                                                    Wygenerowanie pustej drabinki służy do zobrazowania dla uczestników jak wygląda rozkład na niej.
                                                                </p>
                                                            </Message> :
                                                                <Message size='small' warning>
                                                                    <Message.Header>WAŻNE!</Message.Header>
                                                                    <p>Proszę przypisać uczestników do turnieju pucharowego (pocieszenia)
                                                                        <br /> Poprzez wybranie uczestnika <Icon name="add" color="blue" className='hiddenPrint' /> z tabeli grupowej
                                                                    </p>
                                                                </Message>}

                                                        </Grid.Column> : ""}
                                                </Grid.Row>
                                            </Grid>


                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            : ""}
                        {isCupGened && propsRoot.subtournaments && propsRoot.subtournaments.length > 0 ?
                            <>
                                {propsRoot.subtournaments.map((line99, i) => (
                                    line99.parent_subtournament_id === propsRoot.tournamentId ? line99.struct_confirmed === 0 && propsRoot.onlyView === 0 ? <div key={i} style={{ marginTop: 10, marginBottom: 15 }}>
                                        <Header as='h3' block>
                                            <Icon name="winner" />{line99.name}

                                        </Header>
                                        {propsRoot.onlyView === 0 ? <Button size='mini' fluid color='orange' className='cursorPointer hiddenPrint' onClick={() => recreateCupT(line99.subtournament_id)} > USUŃ TURNIEJ PUCHAROWY </Button> : ""}
                                        <StepCreateTournamentGroups ttname={line99.name} third_place_encounter={line99 && line99.third_place_encounter ? line99.third_place_encounter : 0} ttype={line99 && line99.type ? line99.type : ""} members={line99.members ? line99.members.length : 0} tournamentJson={line99.tournament_json} tournamentGroups={line99.groups} struct_confirmed={line99.struct_confirmed} subtournament_id={line99.subtournament_id} />
                                    </div> : line99.struct_confirmed === 1 ?
                                        <div>
                                            <div className='page-break'></div>
                                            <Divider />

                                            <Label color='red' ribbon>
                                                <Icon name="winner" />{line99.name}
                                            </Label>
                                            {propsRoot.onlyView === 0 ? <Icon bordered inverted color='orange' name="trash" className='cursorPointer hiddenPrint' title="USUŃ STRUKTURĘ TURNIEJU PUCHAROWEGO" onClick={() => recreateCupTStruc(line99.subtournament_id)} /> : ""}

                                            <BracketGen subT_type={line99.type_m} viewTV={propsRoot.viewTV} isDarkTheme={propsRoot.isDarkTheme} systemId={propsRoot.systemId} ttname={""} third_place_encounter={line99 && line99.third_place_encounter ? line99.third_place_encounter : 0} ttype={line99 && line99.type ? line99.type : ""} totalMembers={line99 && line99.members ? line99.members.length : 0} remoteJson={line99 && line99.tournament_json ? line99.tournament_json : {}} onlyView={propsRoot.onlyView} tournamentId={line99.subtournament_id} />

                                        </div> : ""
                                        : ""

                                ))}
                            </> : ""}


                    </>

                    : ""
            }
            <Modal
                onClose={() => exitModal()}
                onOpen={() => setOpenUsersAdd(true)}
                open={openUsersAdd}
                centered={width < 900 ? false : true}
                closeIcon
                dimmer='blurring'

            >
                <Modal.Header>Przypisz uczestnika</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Container textAlign='left'>

                            <Form size='small' >
                                <Form.Field
                                    control={Select} selectOnBlur={false}
                                    options={userList}
                                    label="Wybierz uczestnika"
                                    value={formUsersAdd.member_id}
                                    onChange={(event, data) => {
                                        setfromUsersAdd({ ...formUsersAdd, member_id: data.value })
                                    }}
                                    search
                                />
                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>

                    <Button size="tiny" color='black' onClick={() => exitModal()}>
                        WYJDŹ
                    </Button>
                    <Button size="tiny"
                        content="ZATWIERDŹ"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => modifiDataJsonUserACC()}
                        color='google plus'

                        loading={loading}
                        disabled={loading || formUsersAdd.member_id === null}
                    />
                </Modal.Actions>
            </Modal>

            <Modal

                open={openModalScore !== null ? true : false}
                centered={width < 900 ? false : true}



            >
                <Modal.Header >Wprowadź wynik</Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
                    <Modal.Description >
                        <Container textAlign='center'>

                            <Form size='small' onSubmit={saveScorePre} >
                                <Form.Field inline>
                                    <Grid verticalAlign='middle' fluid stackable columns={3}>
                                        <Grid.Row>
                                            <Grid.Column width={7} textAlign='center'><b><label style={{ fontSize: 16, color: "#646bfa" }}>{openModalScore ? openModalScore.member1_name : ""}</label></b><br />
                                                <Input disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} type="number" autoFocus fluid value={openModalScore ? openModalScore.member1_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member1_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #c9ccff", borderRadius: 5 }} />
                                            </Grid.Column>
                                            <Grid.Column textAlign='center' width={2}>
                                                <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }}>VS</label>
                                            </Grid.Column>
                                            <Grid.Column width={7} textAlign='center'>
                                                <b><label style={{ fontSize: 16, color: "#fa6464" }} >{openModalScore ? openModalScore.member2_name : ""}</label></b><br />
                                                <Input disabled={blockEditScore(openModalScore ? openModalScore.member1_score : 0, openModalScore ? openModalScore.member2_score : 0, propsRoot.systemId, openModalScore ? openModalScore.match_details : null)} type="number" fluid value={openModalScore ? openModalScore.member2_score : 0} onChange={(e, d) => setopenModalScore({ ...openModalScore, member2_score: d.value, match_details: propsRoot.systemId === 5 ? null : openModalScore.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #ffc9c9", borderRadius: 5 }} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>
                                {openModalScore ?
                                    <GetScoreDetails tournament_free={propsRoot.tournament_free} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openModalScore.subtournament_group_match_id, member1_name: openModalScore.member1_name, member2_name: openModalScore.member2_name, member1_score: openModalScore.member1_score, member2_score: openModalScore.member2_score, match_details: openModalScore.match_details, match_place: openModalScore.match_place, match_member1_id: openModalScore.subtournament_group_member1_id, match_member2_id: openModalScore.subtournament_group_member2_id }} openModalScore={openModalScore} setopenModalScore={setopenModalScore} />
                                    : ""}
                                <Button size="tiny"
                                    content="ZATWIERDŹ WPROWADZONY WYNIK"
                                    labelPosition='right'
                                    icon='checkmark'
                                    fluid
                                    color='google plus'
                                    loading={loading}
                                    disabled={loading}
                                />
                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions >
                    <Button floated='left' size="tiny" color='grey' labelPosition='right' icon='trash' onClick={() => delScore()} content="KASUJ WYNIK" />
                    <Button size="tiny" color='black' onClick={() => exitModal()}>
                        WYJDŹ
                    </Button>

                </Modal.Actions>
            </Modal>

        </>
    )
}

export default BracketGroups;